import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faListCheck } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const Stepper = ({ className }) => {
  const { t } = useTranslation('common');
  const location = useLocation();

  const steps = [
    { label: t('email'), icon: faEnvelope, active: true },
    { label: t('password'), icon: faLock, active: location.pathname !== '/signup' },
    { label: t('personalInfo'), icon: faListCheck, active: location.pathname.includes('/personalinfo') }
  ];

  const shouldShowStepper = ['/signup', '/setpassword', '/personalinfo'].some(x => location.pathname.includes(x));
  return shouldShowStepper ? (
    <ul className={`stepper ${className || ''}`}>
      {steps.map((step, i) => (
        <li key={i}>
          <div className={`step${i === 0 ? ' first' : ''} ${step.active ? 'active' : 'inactive'}`}></div>
          <p className="label">{step.label}</p>
          {step.icon && (
            <FontAwesomeIcon
              icon={step.icon}
              className={`stepper-icon ${step.active ? 'stepper-icon-active' : 'stepper-icon-inactive'}`}
            />
          )}
        </li>
      ))}
    </ul>
  ) : null;
};

export default Stepper;
