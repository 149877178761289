import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { login } from '../../actions/auth';
import Divider from '../../components/Divider/Divider';
import Password from '../../components/Inputs/Password';

const Login = () => {
  const { handleSubmit, register, formState } = useForm({ mode: 'onSubmit' });
  const { t: loginT } = useTranslation('login');
  const { t: commonT } = useTranslation('common');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [error, setError] = useState('');

  const handleLogin = async values => {
    try {
      await login({ email: values.email, password: values.password, t: loginT })(dispatch);
      navigate('/');
    } catch (err) {
      setError(loginT('invalidLogin'));
    }
  };

  return (
    <div className="wrapper">
      <div className="container-div max-w-md">
        <h1 className="header-1 login-header">{loginT('loginToYourAccount')}</h1>
        <form className="full-width-column" onSubmit={handleSubmit(handleLogin)}>
          <div className="column">
            <label className="form-label">{commonT('emailAddress')}</label>
            <div className="column">
              <input
                {...register('email', { required: commonT('requiredField') })}
                className="text-input"
                type="email"
                placeholder={commonT('enterYourEmail')}
              />
              {formState.errors && (
                <p className={`${formState.errors.email?.message ? 'error-text email-error' : ''}`}>
                  {formState?.errors?.email?.message}
                </p>
              )}
            </div>
          </div>
          <Password
            label={commonT('password')}
            placeholder={commonT('enterYourPassword')}
            errors={formState.errors?.password?.message}
            register={{ ...register('password', { required: commonT('requiredField') }) }}
          />
          {error && <p className="error-text form-error">{error}</p>}
          <button type="submit" className="submit-button">
            {commonT('login')}
          </button>
        </form>
        <div className="flex justify-center mt-4">
          <Link to="/forgotpassword" className="font-normal text-slate-500 hover:text-[#761185]">
            {loginT('forgotYourPassword')}
          </Link>
        </div>
        <Divider className={'m4'}></Divider>
        <div className="flex justify-center gap-1">
          <span className="text-slate-400">{loginT('noAccountYet')}</span>
          <Link to="/signup" className="text-slate-500 hover:text-[#761185]">
            {commonT('signup')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
