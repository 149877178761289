import { Link, useLocation, useNavigate, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

export default function Profile() {
  const { t } = useTranslation('profile');
  const navigate = useNavigate();
  const location = useLocation();
  const activeClasses = 'text-[#761185] border-[#761185]';

  return (
    <div>
      <div className="hidden md:flex gap-8 border-b mb-8 xxl:mb-12">
        <Link
          to="general"
          className={classNames(
            'pb-4 border-b-2',
            location.pathname === '/profile/general' ? activeClasses : 'border-transparent'
          )}
        >
          {t('profileInformation')}
        </Link>
        <Link
          to="questions"
          className={classNames(
            'pb-4 border-b-2',
            location.pathname === '/profile/questions' ? activeClasses : 'border-transparent'
          )}
        >
          {t('userQuestions')}
        </Link>
        <Link
          to="account"
          className={classNames(
            'pb-4 border-b-2',
            location.pathname === '/profile/account' ? activeClasses : 'border-transparent'
          )}
        >
          {t('account')}
        </Link>
      </div>

      <div>
        <select
          id={'profile-select'}
          defaultValue={location.pathname.split('/profile/')[1]}
          onChange={e => navigate(`/profile/${e.target.value}`)}
          className="block md:hidden w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#761185] sm:leading-6 mb-8 xxl:mb-12"
        >
          <option value="general">{t('profileInformation')}</option>
          <option value="questions">{t('userQuestions')}</option>
          <option value="account">{t('account')}</option>
        </select>
      </div>
      <Outlet />
    </div>
  );
}
