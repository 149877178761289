import { useState, useRef, useEffect } from 'react';

const LegendItem = ({ children, color, info }) => {
  const [showPopover, setShowPopover] = useState(false);
  const popoverRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setShowPopover(false);
      }
    };

    if (showPopover) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPopover]);

  const handlePopoverClick = () => {
    if (window.innerWidth <= 1024) {
      setShowPopover(!showPopover);
    }
  };

  const handlePopoverEnter = () => {
    if (window.innerWidth > 1024) {
      setShowPopover(true);
    }
  };

  const handlePopoverLeave = () => {
    if (window.innerWidth > 1024) {
      setShowPopover(false);
    }
  };

  return (
    <div
      className="relative flex items-center curor-default"
      onMouseEnter={handlePopoverEnter}
      onMouseLeave={handlePopoverLeave}
      onClick={handlePopoverClick}
    >
      <span className="w-6 h-3 mr-4 rounded-md" style={{ background: color }} />
      <span className="whitespace-nowrap">{children}</span>
      {showPopover && (
        <div className="absolute w-80 bg-white p-4 border border-grey-100 shadow-lg rounded-lg z-50 -translate-y-[50%] lg:left-1/2">
          {info}
        </div>
      )}
    </div>
  );
};

export default LegendItem;
