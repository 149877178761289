import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const membershipLevels = [
  { label: 'Basic', min: 0, max: 9999 },
  { label: 'Member', min: 10000, max: 49999 },
  { label: 'Member Plus', min: 50000, max: 249999 },
  { label: 'Member Gold', min: 250000 }
];

const useMembership = () => {
  const investedAmount = useSelector(state => state.investments.totalInvestedAmount);
  const [membershipLevel, setMembershipLevel] = useState(null);

  const updateMembershipLevel = amount => {
    for (const { label, min, max } of membershipLevels) {
      if (amount >= min && (!max || amount <= max)) {
        return label;
      }
    }
  };

  useEffect(() => {
    if (investedAmount !== null) {
      setMembershipLevel(updateMembershipLevel(investedAmount));
    }
  }, [investedAmount]);

  return membershipLevel;
};

export default useMembership;
