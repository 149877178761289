import { useTranslation } from 'react-i18next';
import Questions from './profile/questions';

const PersonalInfo = () => {
  const { t: profileT } = useTranslation('profile');

  return (
    <div className="wrapper">
      <div className="container-div max-w-3xl">
        <div className="header-div">
          <h1 className="header-1">{profileT('personalInformation')}</h1>
          <p className="explainer-text">{profileT('thisInfoWillHelpUs')}</p>
        </div>
        <Questions userRegistration={true} />
      </div>
    </div>
  );
};

export default PersonalInfo;
