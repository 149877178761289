import { LOGOUT_SUCCESS } from '../actions/auth';
import axios from 'axios';

export const REQUEST_ACTIONS = { GET: 'get', PUT: 'put', POST: 'post', DELETE: 'delete' };

export const restrictedRequest = async ({ action, url, data, dispatch }) => {
  const args = data || data === null ? [url, data] : [url];
  const axiosInstance = axios.create({ withCredentials: true });
  try {
    const response = await axiosInstance[action](...args);
    return response;
  } catch (err) {
    if ([403, 401].includes(err.response.status) && dispatch) {
      dispatch({
        type: LOGOUT_SUCCESS
      });
      if (url !== `${import.meta.env.VITE_API_URL}/auth/activesession`) {
        window.location.reload();
      }
    }
    throw err;
  }
};
