import { useTranslation } from 'react-i18next';
import { LineChart, Line, XAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import Panel from '../Panel';
import PanelHeading from '../PanelHeading';
import PanelHeader from '../PanelHeader';
import { getDataStartingOneYearBack } from './utils';

const yearData = t => [
  {
    name: `${t('month_jan')}`,
    Market: 0,
    Jibba: 0
  },
  {
    name: `${t('month_feb')}`,
    Market: 0,
    Jibba: 0
  },
  {
    name: `${t('month_mar')}`,
    Market: 0,
    Jibba: 0
  },
  {
    name: `${t('month_apr')}`,
    Market: 0,
    Jibba: 0
  },
  {
    name: `${t('month_may')}`,
    Market: 0,
    Jibba: 0
  },
  {
    name: `${t('month_jun')}`,
    Market: 0,
    Jibba: 0
  },
  {
    name: `${t('month_jul')}`,
    Market: 0,
    Jibba: 0
  },
  {
    name: `${t('month_aug')}`,
    Market: 0,
    Jibba: 0
  },
  {
    name: `${t('month_sep')}`,
    Market: 0,
    Jibba: 0
  },
  {
    name: `${t('month_oct')}`,
    Market: 0,
    Jibba: 0
  },
  {
    name: `${t('month_nov')}`,
    Market: 0,
    Jibba: 0
  },
  {
    name: `${t('month_dec')}`,
    Market: 0,
    Jibba: 0
  }
];

const JibbaVsIndex = () => {
  const { t } = useTranslation();

  return (
    <Panel>
      <div className="flex flex-col items-end">
        <PanelHeader>
          <PanelHeading info={t('marketVsJibbaInfo')} title={t('marketVsJibba')} />
        </PanelHeader>

        <div className="w-full">
          <ResponsiveContainer width="100%" height={200}>
            <LineChart data={getDataStartingOneYearBack(yearData(t))}>
              <CartesianGrid strokeDasharray="3 6" />
              <XAxis dataKey="name" />
              <Tooltip />
              <Line
                type="monotone"
                name="Riks"
                dataKey="Market"
                stroke="#4f46e5"
                strokeWidth={3}
                activeDot={{ r: 8 }}
              />
              <Line type="monotone" name="Jibba" dataKey="Jibba" stroke="#a853f7" strokeWidth={3} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </Panel>
  );
};

export default JibbaVsIndex;
