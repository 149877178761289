import { useTranslation } from 'react-i18next';
import Stat from './Stat';

const InvestTable = ({ investedAmountFormatted, accumulatedInterestFormatted }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-center w-full">
      <div className="divide-y">
        <Stat tight value={investedAmountFormatted ? `${investedAmountFormatted} kr` : '0 kr'} loading={false}>
          {t('totalAmountInvested')}
        </Stat>
        <Stat tight value={accumulatedInterestFormatted ? `+${accumulatedInterestFormatted} kr` : '0 kr'}>
          {t('interestIncome')}
        </Stat>
      </div>
    </div>
  );
};

export default InvestTable;
