import { useTranslation } from 'react-i18next';
import SectionHeading from '../components/SectionHeading';
import JibbaVsIndex from '../components/Charts/JibbaVsIndex';
import Stats from '../components/Charts/Stats';
import InvestmentForecast from '../components/Charts/InvestmentForecast';
import FadeIn from '../components/FadeIn';
import WithdrawBtn from '../components/WithdrawBtn';

const Earnings = () => {
  const { t } = useTranslation();

  return (
    <FadeIn>
      <div>
        <SectionHeading heading={t('myPortfolio')}>
          {import.meta.env.VITE_ENV === 'dev' ? <WithdrawBtn /> : null}
        </SectionHeading>

        <Stats />

        <div className="flex flex-col justify-between gap-10 mt-10 md:flex-row">
          <JibbaVsIndex />
          <InvestmentForecast />
        </div>
      </div>
    </FadeIn>
  );
};

export default Earnings;
