import classNames from 'classnames';
import Spinner from './Spinner';

const Button = ({ children, type, className, loading, small, big, secondary, disabled, ...rest }) => (
  <button
    type={type ?? 'button'}
    className={classNames(
      'min-w-[6rem] border-2 rounded-lg font-semibold tracking-wide shadow-sm hover:bg-opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#761185] disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed',
      small ? 'px-3 py-2 text-sm' : 'px-3.5 py-2.5 text-base',
      big && 'w-full py-3 text-lg',
      secondary
        ? 'bg-white text-[#761185] border-fuchsia-100 hover:border-opacity-60 hover:text-fuchsia-600'
        : 'bg-[#761185] text-white border-transparent',
      disabled && '!border-gray-100',
      className
    )}
    disabled={loading || disabled}
    {...rest}
  >
    {loading ? <Spinner /> : <>{children}</>}
  </button>
);

export default Button;
