import { REQUEST_ACTIONS, restrictedRequest } from '../services/request';

const API_URL = `${import.meta.env.VITE_API_URL}/user`;

export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const getUserInfo = () => async dispatch => {
  const response = await restrictedRequest({ action: REQUEST_ACTIONS.GET, url: `${API_URL}/userinfo`, dispatch });
  const userInfo = response.data.user;
  return dispatch({ type: GET_USER_INFO_SUCCESS, payload: userInfo });
};

export const GET_KYC_SUCCESS = 'GET_KYC_SUCCESS';
export const getKYC = () => async dispatch => {
  const response = await restrictedRequest({ action: REQUEST_ACTIONS.GET, url: `${API_URL}/kyc`, dispatch });
  const kyc = response.data.kyc;
  return dispatch({ type: GET_KYC_SUCCESS, payload: kyc });
};

export const SAVE_USER_INFO_SUCCESS = 'SAVE_USER_INFO_SUCCESS';
export const saveUserInfo =
  ({ firstName, lastName, email, phone, country, city, street, zipCode }) =>
  async dispatch => {
    const response = await restrictedRequest({
      action: REQUEST_ACTIONS.POST,
      url: `${API_URL}/userinfo`,
      data: {
        firstName,
        lastName,
        email,
        phone,
        country: country?.value,
        city,
        street,
        zipCode
      },
      dispatch
    });
    const userInfo = response.data.user;
    return dispatch({ type: SAVE_USER_INFO_SUCCESS, payload: userInfo });
  };

export const SAVE_KYC_SUCCESS = 'SAVE_KYC_SUCCESS';
export const COMPLETED_KYC = 'COMPLETED_KYC';
export const saveKYC =
  ({ occupation, income, incomeType, outsideAccess, timeHorizon, depositFrequency, pep, ownHoldings }) =>
  async dispatch => {
    const response = await restrictedRequest({
      action: REQUEST_ACTIONS.POST,
      url: `${API_URL}/kyc`,
      data: {
        occupation,
        income,
        incomeType,
        outsideAccess,
        timeHorizon,
        depositFrequency,
        pep,
        ownHoldings
      },
      dispatch
    });
    const { kyc, hasCompletedKYC } = response.data;
    if (hasCompletedKYC) {
      dispatch({ type: COMPLETED_KYC });
    }
    return dispatch({ type: SAVE_KYC_SUCCESS, payload: kyc });
  };
