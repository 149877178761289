import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Password = ({ label, placeholder, errors, register }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [focused, setFocused] = useState(false);

  const changeShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const setFocus = () => {
    setFocused(!focused);
  };

  const trimSpaces = event => {
    event.target.value = event.target.value.replaceAll(' ', '');
  };

  return (
    <div className="column mb-4">
      <label className="form-label">{label}</label>
      <div
        className={`flex w-full pr-4 border border-gray-400 rounded-md bg-white ${
          focused ? 'ring-1 ring-[#761185]' : ''
        }`}
        onFocus={setFocus}
        onBlur={setFocus}
      >
        <input
          className="w-full pl-4 py-3 rounded-md border-0 focus:ring-0"
          {...register}
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder}
          onInput={trimSpaces}
        />
        <button type="button" onClick={changeShowPassword}>
          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
        </button>
      </div>
      {errors && <pre className="error-text">{errors}</pre>}
    </div>
  );
};

export default Password;
