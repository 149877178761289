import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';

const PanelHeading = ({ info, title, className }) => {
  const [showPopover, setShowPopover] = useState(false);

  const handlePopoverEnter = () => {
    if (window.innerWidth > 1024 && !showPopover) {
      setShowPopover(true);
    }
  };

  const handlePopoverLeave = () => {
    if (window.innerWidth > 1024 && showPopover) {
      setShowPopover(false);
    }
  };

  const handlePopoverClick = () => {
    if (window.innerWidth <= 1024) {
      setShowPopover(!showPopover);
    }
  };

  return (
    <div className="relative">
      <div className="flex flex-row items-center gap-2">
        <h2 className={`max-w-[11rem] lg:max-w-[20rem] text-xl text-gray-600 ${className}`}>{title}</h2>
        {info && (
          <>
            <FontAwesomeIcon
              icon={faCircleQuestion}
              className="w-5 h-5 text-gray-800 cursor-pointer lg:cursor-default"
              onClick={handlePopoverClick}
              onMouseEnter={handlePopoverEnter}
              onMouseLeave={handlePopoverLeave}
            />
            {showPopover && (
              <div className="absolute w-[15rem] lg:w-[18rem] bg-white p-4 border border-grey-100 shadow-lg rounded-lg z-50 -translate-y-[60%] lg:left-1/2">
                {info}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PanelHeading;
