import { REQUEST_ACTIONS, restrictedRequest } from '../services/request';

const API_URL = `${import.meta.env.VITE_API_URL}/investments`;

export const GET_TOTAL_INVESTED_AMOUNT = 'GET_TOTAL_INVESTED_AMOUNT';
export const getTotalInvestedAmount = () => async dispatch => {
  const response = await restrictedRequest({ action: REQUEST_ACTIONS.GET, url: API_URL, dispatch });
  const { totalInvestedAmount } = response.data;
  return dispatch({ type: GET_TOTAL_INVESTED_AMOUNT, payload: totalInvestedAmount });
};

export const ADD_INVESTMENT_SUCCESS = 'ADD_INVESTMENT_SUCCESS';
export const addInvestment = amount => async dispatch => {
  const response = await restrictedRequest({ action: REQUEST_ACTIONS.POST, url: API_URL, data: { amount }, dispatch });
  const { newInvestment } = response.data;
  return dispatch({ type: ADD_INVESTMENT_SUCCESS, payload: newInvestment });
};

export const GET_INVESTMENT_HISTORY = 'GET_INVESTMENT_HISTORY';
export const getInvestmentHistory = () => async dispatch => {
  const response = await restrictedRequest({ action: REQUEST_ACTIONS.GET, url: `${API_URL}/history`, dispatch });
  const { investmentHistory } = response.data;
  return dispatch({ type: GET_INVESTMENT_HISTORY, payload: investmentHistory });
};

export const swishPay = amount => async dispatch => {
  const response = await restrictedRequest({
    action: REQUEST_ACTIONS.POST,
    url: `${API_URL}/swish`,
    data: { amount },
    dispatch
  });

  const { id, token } = response.data;
  const callbackUrl = `https://myfrontend.com/receipt?ref=${id}`;
  const appUrl = `swish://paymentrequest?token=${token}&callbackurl=${callbackUrl}`;

  // Open or redirect the user to the url
  window.location.href = appUrl;
};

export const CLICKED_NOTIFY_BEFORE_LAUNCH = 'CLICKED_NOTIFY_BEFORE_LAUNCH';
export const notifyBeforeLaunch = () => async dispatch => {
  await restrictedRequest({
    action: REQUEST_ACTIONS.POST,
    url: `${API_URL}/notifybeforelaunch`,
    dispatch
  });
  dispatch({ type: CLICKED_NOTIFY_BEFORE_LAUNCH, payload: { hasClickedNotifyBeforeLaunch: true } });
};

export const saveInvestmentFormInput = amount => async dispatch => {
  await restrictedRequest({
    action: REQUEST_ACTIONS.POST,
    url: `${API_URL}/investmentforminput`,
    data: { amount },
    dispatch
  });
};
