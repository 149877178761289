import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { Squares2X2Icon, BoltIcon, BriefcaseIcon } from '@heroicons/react/24/outline';

const SideNavigation = () => {
  const { t } = useTranslation();
  const isAuthenticated = useSelector(x => x.auth.isAuthenticated);
  const location = useLocation();
  const showNavbar = isAuthenticated && !location.pathname.includes('/personalinfo');

  const navItems = [
    {
      label: t('overview'),
      icon: Squares2X2Icon,
      link: '/overview'
    },
    {
      label: t('invest'),
      icon: BoltIcon,
      link: '/invest'
    },
    {
      label: t('myPortfolio'),
      icon: BriefcaseIcon,
      link: '/earnings'
    }
  ];

  return (
    showNavbar && (
      <div className="fixed bottom-0 w-full bg-white border-y border-slate-200 z-10 md:relative md:bottom-auto md:w-auto md:w-[13.25rem] md:-mt-[var(--headerHeight)] md:border-r md:border-y-0">
        <div className="flex items-center justify-center h-20 md:sticky md:top-0 md:flex-col md:justify-between md:min-h-screen md:pt-[var(--headerHeight)] md:px-4">
          <div className="flex w-[32rem] px-2 md:flex-col md:w-[12rem] md:gap-4 md:pt-[2.75rem] 2xl:pt-[3.2rem] md:px-0">
            {navItems.map(item => (
              <NavbarItem item={item} key={item.label} />
            ))}
          </div>
        </div>
      </div>
    )
  );
};

const NavbarItem = ({ item }) => (
  <NavLink
    className={({ isActive }) =>
      `flex flex-col items-center gap-1 w-full py-2 px-5 rounded text-sm font-medium md:flex-row md:gap-2 md:text-lg  ${
        isActive ? 'text-fuchsia-700 bg-fuchsia-50 md:bg-fuchsia-100' : 'text-gray-500'
      }`
    }
    to={item.link}
  >
    <item.icon className="h-6" />
    <p>{item.label}</p>
  </NavLink>
);

export default SideNavigation;
