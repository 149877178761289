import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import commonEN from '../translations/en/common.json';
import commonSV from '../translations/sv/common.json';
import profileEN from '../translations/en/profile.json';
import profileSV from '../translations/sv/profile.json';
import loginEN from '../translations/en/login.json';
import loginSV from '../translations/sv/login.json';
import investEN from '../translations/en/invest.json';
import investSV from '../translations/sv/invest.json';

const resources = {
  en: {
    common: commonEN,
    profile: profileEN,
    login: loginEN,
    invest: investEN
  },
  sv: {
    common: commonSV,
    profile: profileSV,
    login: loginSV,
    invest: investSV
  }
};

export const initializeI18n = lng =>
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      fallbackLng: 'sv',
      defaultNS: 'common',
      fallbackNS: 'common',
      ns: 'common',
      lng,
      preload: ['en', 'sv'], // allow using multiple lngs without switching base lng (e.g. change requests)
      interpolation: {
        escapeValue: false // react already safes from xss
      },
      react: {
        useSuspense: true
      }
    });
