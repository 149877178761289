const EarningCard = ({ title, value, percentage, period }) => {
  return (
    <div className="flex flex-col justify-between w-full md:w-1/2 bg-white md:h-40 rounded-lg px-6 py-4 shadow-lg border border-slate-200">
      <h2 className="text-gray-600 text-lg font-semibold">{title}</h2>

      <p className="text-[#334155] font-semibold text-[32px]">{value}</p>

      <div className="flex justify-between items-center">
        <div className="flex gap-2 items-center text-[#34D399] font-semibold text-lg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18" />
          </svg>

          <p>{percentage}</p>
        </div>
        <p className="text-gray-500 text-sm xl:text-base">{period}</p>
      </div>
    </div>
  );
};

export default EarningCard;
