import i18n from 'i18next';
import { useState } from 'react';

export const useLanguage = () => {
  const [language, setLanguage] = useState(window.localStorage.getItem('language') || i18n.language || 'sv');
  i18n.on('languageChanged', lng => {
    window.localStorage.setItem('language', lng);
    setLanguage(lng);
  });
  return language;
};

export const changeLanguage = lng => {
  i18n.changeLanguage(lng);
};
