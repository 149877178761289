import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, useFormState } from 'react-hook-form';
import Button from '../../components/Button';
import Form from '../../components/Form';
import Panel from '../../components/Panel';
import { Select, YesNoRadio } from '../../components/Inputs';
import inputOptions, { mapToSelect } from '../../utils/options';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import Spinner from '../../components/Spinner';
import { getKYC, saveKYC } from '../../actions/user';

const _getDataToSave = data =>
  Object.entries(data).reduce((acc, [key, value]) => {
    const isBoolean = ['yes', 'no'].includes(value);
    if (isBoolean) {
      acc[key] = value === 'yes';
    } else if ([null, undefined].includes(value)) {
      return acc;
    } else {
      acc[key] = value.value || value;
    }
    return acc;
  }, {});

const _getLabelFromFieldName = (fieldName, value) => inputOptions[`${fieldName}Options`].get(value);

const _getValueFromkyc = (kyc, t) => fieldName => {
  const value = kyc?.[fieldName];
  if (typeof value === 'boolean') return value ? 'yes' : 'no';
  if ([null, undefined].includes(value)) return null;
  const label = t(_getLabelFromFieldName(fieldName, value));
  return { value, label };
};

const QuestionsOne = ({ setStep, kyc, dispatch, setError }) => {
  const {
    t,
    i18n: { language }
  } = useTranslation('profile');
  const [loading, setLoading] = useState(false);

  const _getValueFromFieldName = _getValueFromkyc(kyc, t);
  const { control, handleSubmit, getValues, reset } = useForm({
    defaultValues: {
      occupation: _getValueFromFieldName('occupation') || '',
      income: _getValueFromFieldName('income') || '',
      incomeType: _getValueFromFieldName('incomeType') || '',
      outsideAccess: _getValueFromFieldName('outsideAccess')
    }
  });
  const { isDirty, isValid } = useFormState({ control });

  useEffect(() => {
    const getCurrentValueFromFieldName = _getValueFromkyc(_getDataToSave(getValues()), t);
    reset({
      occupation: getCurrentValueFromFieldName('occupation') || '',
      income: getCurrentValueFromFieldName('income') || '',
      incomeType: getCurrentValueFromFieldName('incomeType') || '',
      outsideAccess: getCurrentValueFromFieldName('outsideAccess')
    });
  }, [language, getValues, reset, t]);

  const occupationOptions = mapToSelect(inputOptions.occupationOptions, t);
  const incomeOptions = mapToSelect(inputOptions.incomeOptions, t);
  const incomeTypeOptions = mapToSelect(inputOptions.incomeTypeOptions, t);

  const _onSubmit = async ({ occupation, income, incomeType, outsideAccess }) => {
    if (!isDirty) return setStep('two');
    const dataToSave = _getDataToSave({ occupation, income, incomeType, outsideAccess });
    setLoading(true);
    try {
      await dispatch(saveKYC(dataToSave));
      reset({ occupation, income, incomeType, outsideAccess });
      setStep('two');
    } catch (_) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <p className="mb-10 leading-6 text-gray-600">
        1/2 <span className="ml-3 text-black">{t('questionsAboutYou')}</span>
      </p>

      <Form onSubmit={handleSubmit(_onSubmit)}>
        <div className="sm:col-span-3">
          <Select
            control={control}
            name="occupation"
            inputId="occupation"
            label={t('currentOccupation')}
            options={occupationOptions}
            isSearchable={false}
            rules={{ required: true }}
          />
        </div>
        <div className="sm:col-span-3">
          <Select
            control={control}
            name="income"
            inputId="income"
            label={t('monthlyIncomeBeforeTaxes')}
            options={incomeOptions}
            isSearchable={false}
            rules={{ required: true }}
          />
        </div>
        <div className="sm:col-span-3">
          <Select
            control={control}
            name="incomeType"
            inputId="incomeType"
            label={t('monthlyIncomeConsist')}
            options={incomeTypeOptions}
            isSearchable={false}
            rules={{ required: true }}
          />
        </div>
        <div className="sm:col-span-3">
          <YesNoRadio
            control={control}
            name="outsideAccess"
            inputId="outsideAccess"
            label={t('accessToAccount')}
            rules={{ required: true }}
          />
        </div>

        <div className="col-span-full flex justify-end mt-2">
          <Button type="submit" className="flex items-center" loading={loading} disabled={!isValid}>
            {t('next')}
            <ArrowRightIcon className="h-6 ml-2" />
          </Button>
        </div>
      </Form>
    </>
  );
};

const QuestionsTwo = ({ setStep, kyc, dispatch, userRegistration, setError }) => {
  const {
    t,
    i18n: { language }
  } = useTranslation('profile');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);

  const _getValueFromFieldName = _getValueFromkyc(kyc, t);
  const { control, handleSubmit, getValues, reset } = useForm({
    defaultValues: {
      timeHorizon: _getValueFromFieldName('timeHorizon'),
      depositFrequency: _getValueFromFieldName('depositFrequency'),
      pep: _getValueFromFieldName('pep'),
      ownHoldings: _getValueFromFieldName('ownHoldings')
    }
  });

  useEffect(() => {
    const getCurrentValueFromFieldName = _getValueFromkyc(_getDataToSave(getValues()), t);
    reset({
      timeHorizon: getCurrentValueFromFieldName('timeHorizon'),
      depositFrequency: getCurrentValueFromFieldName('depositFrequency'),
      pep: getCurrentValueFromFieldName('pep'),
      ownHoldings: getCurrentValueFromFieldName('ownHoldings')
    });
  }, [language, getValues, reset, t]);

  const { isDirty, isValid } = useFormState({ control });

  const depositFrequencyOptions = mapToSelect(inputOptions.depositFrequencyOptions, t);
  const timeHorizonOptions = mapToSelect(inputOptions.timeHorizonOptions, t);

  const _onSubmit = async ({ timeHorizon, depositFrequency, pep, ownHoldings }) => {
    const dataToSave = _getDataToSave({ timeHorizon, depositFrequency, pep, ownHoldings });
    setLoading(true);
    try {
      await dispatch(saveKYC(dataToSave));
      if (userRegistration) {
        navigate('/');
      } else {
        reset({ timeHorizon, depositFrequency, pep, ownHoldings });
        setSaved(true);
        setTimeout(() => setSaved(false), 4000);
      }
    } catch (_) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <p className="mb-10 leading-6 text-gray-600">
        2/2 <span className="ml-3 text-black">{t('purpose')}</span>
      </p>

      <Form onSubmit={handleSubmit(_onSubmit)}>
        <div className="sm:col-span-3">
          <Select
            control={control}
            name="timeHorizon"
            inputId="timeHorizon"
            label={t('investmentHorizon')}
            options={timeHorizonOptions}
            isSearchable={false}
            rules={{ required: true }}
          />
        </div>

        <div className="sm:col-span-3">
          <Select
            control={control}
            name="depositFrequency"
            inputId="depositFrequency"
            label={t('depositFrequency')}
            options={depositFrequencyOptions}
            isSearchable={false}
            rules={{ required: true }}
          />
        </div>

        <div className="sm:col-span-full">
          <YesNoRadio control={control} name="pep" label={t('pep')} rules={{ required: true }} />
        </div>

        <div className="sm:col-span-full">
          <YesNoRadio
            control={control}
            name="ownHoldings"
            label={t('holdings')}
            description={t('holdingsDescription')}
            rules={{ required: true }}
          />
        </div>

        <div className="col-span-full flex justify-end gap-10 mt-2">
          <button
            type="button"
            onClick={() => setStep('one')}
            className="flex items-center font-semibold leading-6 text-[#761185] tracking-wide hover:text-fuchsia-500"
          >
            <ArrowLeftIcon className="h-6 mr-2" />
            {t('back')}
          </button>
          <Button type="submit" loading={loading} disabled={!isValid || (!userRegistration && !isDirty) || saved}>
            {userRegistration ? t('continue') : saved ? t('saved') : t('save')}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default function Questions({ userRegistration }) {
  const { t } = useTranslation('common');
  const [step, setStep] = useState('one');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const kyc = useSelector(state => state.user.kyc);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!kyc) {
      dispatch(getKYC())
        .then(() => setLoading(false))
        .catch(() => setError(true));
    } else setLoading(false);
  }, [dispatch, kyc]);

  const STEPS = {
    one: <QuestionsOne setStep={setStep} kyc={kyc} dispatch={dispatch} setError={setError} />,
    two: (
      <QuestionsTwo
        setStep={setStep}
        kyc={kyc}
        dispatch={dispatch}
        userRegistration={userRegistration}
        setError={setError}
      />
    )
  };

  return (
    <Panel>
      {!error && !loading && <>{STEPS[step]}</>}
      {loading && !error && <Spinner />}
      {error && <p>{t('somethingWentWrong')}</p>}
    </Panel>
  );
}
