import { default as ReactSelect } from 'react-select';
import { Controller } from 'react-hook-form';
import selectStyles from '../../styles/selectStyles';
import { t } from 'i18next';

const Select = ({ control, options, name, label, rules, ...rest }) => (
  <>
    {label && (
      <label htmlFor={name} className="block mb-2 text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
    )}
    <div>
      {control ? (
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <ReactSelect
              options={options}
              onChange={onChange}
              value={value}
              styles={selectStyles}
              placeholder={t('selectPlaceholder')}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              {...rest}
            />
          )}
          rules={rules}
        ></Controller>
      ) : (
        <ReactSelect options={options} styles={selectStyles} {...rest} placeholder={t('selectPlaceholder')} />
      )}
    </div>
  </>
);

export default Select;
