import { REQUEST_ACTIONS, restrictedRequest } from '../services/request';

const API_URL = `${import.meta.env.VITE_API_URL}/bankaccounts`;

export const BANK_ACCOUNT_VERIFICATION_SUCCESS = 'BANK_ACCOUNT_VERIFICATION_SUCCESS';
export const verifyBankAccount = reportId => dispatch =>
  restrictedRequest({ action: REQUEST_ACTIONS.GET, url: `${API_URL}/verify/${reportId}`, dispatch });

export const BANK_ACCOUNT_REMOVAL_SUCCESS = 'BANK_ACCOUNT_REMOVAL_SUCCESS';
export const removeBankAccount = bankAccountId => async dispatch => {
  await restrictedRequest({ action: REQUEST_ACTIONS.DELETE, url: `${API_URL}/${bankAccountId}`, dispatch });
  return dispatch({ type: BANK_ACCOUNT_REMOVAL_SUCCESS, payload: bankAccountId });
};

export const GET_BANK_ACCOUNTS_SUCCESS = 'GET_BANK_ACCOUNTS_SUCCESS';
export const getBankAccounts = () => async dispatch => {
  const response = await restrictedRequest({ action: REQUEST_ACTIONS.GET, url: API_URL, dispatch });
  const bankAccounts = response.data.bankAccounts;
  return dispatch({ type: GET_BANK_ACCOUNTS_SUCCESS, payload: bankAccounts });
};

export const validateStateId = stateId => dispatch =>
  restrictedRequest({ action: REQUEST_ACTIONS.POST, url: `${API_URL}/validatestateid`, data: { stateId }, dispatch });
