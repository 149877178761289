import classNames from 'classnames';
import Spinner from './Spinner';

const Stat = ({ value, tight, loading, children }) => (
  <div
    className="flex justify-between items-center py-5"
    style={{
      paddingTop: tight ? '0.75rem' : '1.5rem',
      paddingBottom: tight ? '0.75rem' : '1.5rem'
    }}
  >
    <span className={classNames('text-gray-600', tight && 'text-sm md:text-lg')}>{children}</span>
    <span className={classNames('font-bold', tight && 'text-sm md:text-lg')}>
      {loading ? <Spinner /> : <>{value}</>}
    </span>
  </div>
);

export default Stat;
