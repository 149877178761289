const selectStyles = {
  control: (styles, state) => ({
    ...styles,
    borderRadius: '6px',
    borderColor: state.isFocused ? '#4f46e5' : styles.borderColor,
    cursor: 'pointer',
    '&:hover': {
      borderColor: state.isFocused ? '#4f46e5' : styles.borderColor
    },
    '*': {
      boxShadow: 'none !important'
    }
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected ? '#4f46e5' : styles.color,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: state.isSelected ? '#4f46e5' : '#f2f1f9'
    }
  })
};

export default selectStyles;
