import { useTranslation } from 'react-i18next';
import useMembership from '../hooks/useMembership';
import Panel from '../components/Panel';

const MembershipTable = () => {
  const { t } = useTranslation('profile');
  const membershipLevel = useMembership();

  const membershipLevels = [
    {
      label: 'Basic',
      invested: '< 10K',
      serviceCharge: '0%',
      brokerage: '5%'
    },
    {
      label: 'Member',
      invested: '10K',
      serviceCharge: '0%',
      brokerage: '5%'
    },
    {
      label: 'Member Plus',
      invested: '50K',
      serviceCharge: '0%',
      brokerage: '2.5%'
    },
    {
      label: 'Member Gold',
      invested: '250K',
      serviceCharge: '0%',
      brokerage: '1%'
    }
  ];

  const Row = ({ label, invested, serviceCharge, brokerage }) => {
    const isCurrentLevel = label === membershipLevel;
    const className = `px-6 py-4${isCurrentLevel ? ' font-medium text-black' : ''}`;
    return (
      <tr className={isCurrentLevel ? 'bg-fuchsia-50' : 'bg-white'}>
        <th scope="row" className={`${className} text-gray-900 whitespace-nowrap`}>
          {label}
        </th>
        <td className={className}>{`${t('invested')}: ${invested}`}</td>
        <td className={className}>{serviceCharge}</td>
        <td className={className}>{brokerage}</td>
      </tr>
    );
  };

  const Header = ({ headerName, children }) => (
    <th scope="col" className="px-6 py-3">
      {t(headerName)}
      {children}
    </th>
  );

  return (
    <Panel>
      <h4 className="mb-8 leading-6 text-lg text-gray-900">{t('myMembershipLevel')}</h4>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg border border-slate-200">
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-base text-gray-700 bg-gray-50">
            <tr>
              <Header headerName={'level'} />
              <Header headerName={'terms'} />
              <Header headerName={'serviceCharge'}>*</Header>
              <Header headerName={'brokerage'}>**</Header>
            </tr>
          </thead>
          <tbody>
            {membershipLevels.map(({ level, label, invested, serviceCharge, brokerage }, index) => (
              <Row
                key={index}
                level={level}
                label={label}
                invested={invested}
                serviceCharge={serviceCharge}
                brokerage={brokerage}
              />
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-8 pl-2">
        <p className="mt-2 text-sm font-normal text-gray-500">{t('serviceFee')}</p>
        <p className="mt-1 text-sm font-normal text-gray-500">{t('secondaryMarketFee')}</p>
      </div>
    </Panel>
  );
};

export default MembershipTable;
