import { BarChart, Bar, XAxis, ResponsiveContainer, Tooltip } from 'recharts';
import { useTranslation } from 'react-i18next';
import LegendItem from './LegendItem';

const OverviewBarChart = () => {
  const { t } = useTranslation();

  const getMonthName = offset => {
    const today = new Date();
    const targetMonth = new Date(today.getFullYear(), today.getMonth() + offset, 1);
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return monthNames[targetMonth.getMonth()];
  };

  const data = [
    {
      name: getMonthName(-2),
      [t('valueJib')]: 0,
      [t('interestIncome')]: 0
    },
    {
      name: getMonthName(-1),
      [t('valueJib')]: 0,
      [t('interestIncome')]: 0
    },
    {
      name: getMonthName(0),
      [t('valueJib')]: 0,
      [t('interestIncome')]: 0
    },
    {
      name: getMonthName(1),
      [t('forecastedJib')]: 0,
      [t('forecastedInterestIncome')]: 0
    },
    {
      name: getMonthName(2),
      [t('forecastedJib')]: 0,
      [t('forecastedInterestIncome')]: 0
    }
  ];

  return (
    <div className="flex flex-col-reverse justify-between mt-6 md:gap-12 md:flex-row  md:items-center 2xl:mt-10">
      <div className="flex flex-col space-y-6">
        <LegendItem color="#4f46e5" info={t('valueJibInfo')}>
          {t('valueJib')}
        </LegendItem>
        <LegendItem color="#818cf8" info={t('interestIncomeInfo')}>
          {t('interestIncome')}
        </LegendItem>
        <LegendItem color="#a854f7" info={t('forecastedJibInfo')}>
          {t('forecastedJib')}
        </LegendItem>
        <LegendItem color="#c4b5fd" info={t('forecastedInterestIncomeInfo')}>
          {t('forecastedInterestIncome')}
        </LegendItem>
      </div>
      <div className="w-full">
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={data}>
            <XAxis dataKey="name" height={60} dy={10} />
            <Tooltip cursor={{ fill: '#f0f0f0' }} />
            <Bar barSize={50} dataKey={t('valueJib')} stackId="a" fill="#4f46e5" />
            <Bar barSize={50} dataKey={t('interestIncome')} stackId="a" fill="#818cf8" radius={[10, 10, 0, 0]} />
            <Bar barSize={50} dataKey={t('forecastedJib')} stackId="a" fill="#a854f7" />
            <Bar
              barSize={50}
              dataKey={t('forecastedInterestIncome')}
              stackId="a"
              fill="#c4b5fd"
              radius={[10, 10, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default OverviewBarChart;
