import { useTranslation } from 'react-i18next';

const HistoryItem = ({ type, date, amount }) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-between items-end">
      <div className="flex gap-4 items-center">
        <div className="w-10 h-10 relative">
          <img
            src={`${type === 'investment' ? '/investment-logo.png' : '/withdrawn-logo.png'}`}
            alt={`${type === 'investment' ? 'investment' : 'withdrawn'}`}
          />
        </div>
        <div className="column ">
          <p className="text-slate-700 font-semibold text-base md:text-lg">{`${
            type === 'investment' ? t('invested') : t('withdrawn')
          }`}</p>
          <p className="text-gray-500 font-medium text-sm md:text-base">{date}</p>
        </div>
      </div>
      <p className="text-slate-800 font-semibold text-lg md:text-2xl">{amount} kr</p>
    </div>
  );
};

const InvestHistory = ({ investments }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex justify-between items-end">
        <p className="font-spaceGrotesk font-bold text-lg md:text-2xl text-slate-800">{t('transactionHistory')}</p>
        {/* {investments && investments.length > 0 && (
          <button className="underline text-[#2D3648] font-spaceGrotesk font-bold text-md md:text-lg">
            {t('seeAll')}
          </button>
        )} */}
      </div>
      <div className="mt-6 column gap-6">
        {investments && investments.length > 0 ? (
          <>
            {investments.map(investment => (
              <HistoryItem
                key={investment.createdAt}
                type="investment"
                amount={investment.amount}
                date={new Date(investment.createdAt).toLocaleDateString('sv-SE')}
              />
            ))}
          </>
        ) : (
          <p className="text-slate-800 font-semibold">{t('noHistory')}</p>
        )}
      </div>
    </div>
  );
};

export default InvestHistory;
