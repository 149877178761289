import { useState, useRef } from 'react';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import Panel from '../Panel';
import Button from '../Button';

const exampleData = [
  {
    id: 'JIB-011',
    date: '2021-01-05',
    forcastedDevelopment: 34,
    interest: 5,
    value: 1750
  },
  {
    id: 'JIB-012',
    date: '2021-01-06',
    forcastedDevelopment: 32,
    interest: 1,
    value: 800
  },
  {
    id: 'JIB-013',
    date: '2021-01-07',
    forcastedDevelopment: 30,
    interest: 2,
    value: 1050
  },
  {
    id: 'JIB-014',
    date: '2021-01-08',
    forcastedDevelopment: 32,
    interest: 3,
    value: 1300
  },
  {
    id: 'JIB-015',
    date: '2021-01-09',
    forcastedDevelopment: 33,
    interest: 4,
    value: 1550
  }
];

const SellTable = () => {
  const [totalValue, setTotalValue] = useState(0);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [selectedInterest, setSelectedInterest] = useState(null);
  const checkboxesRef = useRef([]);

  const handleCheckboxChange = (event, data) => {
    const { checked } = event.target;
    setTotalValue(prevTotalValue => prevTotalValue + (checked ? data.value : -data.value));
  };

  const handleHeaderCheckboxChange = event => {
    const { checked } = event.target;

    checkboxesRef.current.forEach(checkbox => {
      if (checkbox.checked !== checked) {
        checkbox.checked = checked;
        handleCheckboxChange(
          { target: checkbox },
          exampleData.find(data => data.id === checkbox.id)
        );
      }
    });
  };

  const handleSort = column => {
    setSortColumn(column);
    setSortDirection(sortColumn === column ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc');
  };

  const sortedData = exampleData.sort((a, b) => {
    if (sortColumn) {
      const aValue = sortColumn === 'date' ? new Date(a[sortColumn]) : a[sortColumn];
      const bValue = sortColumn === 'date' ? new Date(b[sortColumn]) : b[sortColumn];
      return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
    }
    return 0;
  });

  const handleInterestChange = event => {
    setSelectedInterest(Number(event.target.value));
  };

  const filteredData = selectedInterest ? sortedData.filter(data => data.interest === selectedInterest) : sortedData;

  return (
    <Panel>
      <div className="flex flex-col items-center justify-between gap-4 text-center md:flex-row">
        <p className="text-gray-800">Select the credits you want to sell.</p>
        <div className="flex items-center">
          <label htmlFor="interest-rate" className="mr-2 text-gray-800">
            Interest rate:
          </label>
          <select
            id="interest-rate"
            className="w-32 px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-fuchsia-500 focus:border-transparent"
            onChange={handleInterestChange}
            value={selectedInterest}
          >
            <option value="">All</option>
            <option value={1}>1%</option>
            <option value={2}>2%</option>
            <option value={3}>3%</option>
            <option value={4}>4%</option>
            <option value={5}>5%</option>
          </select>
        </div>
      </div>
      <div className="relative mt-6 overflow-x-auto border border-slate-200 sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 text-center">
          <thead className="text-xs text-gray-900 uppercase bg-gray-100">
            <tr>
              <th scope="col" className="py-4 pl-4">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="w-4 h-4 text-[#761185] bg-gray-100 border-gray-300 rounded focus:ring-fuchsia-500 focus:ring-2 cursor-pointer"
                    onChange={handleHeaderCheckboxChange}
                  />
                </div>
              </th>
              <th scope="col" className="pl-2 pr-6 py-3">
                <div className="flex items-center justify-center select-none whitespace-nowrap">Credit ID</div>
              </th>
              <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('date')}>
                <div className="flex items-center justify-center select-none whitespace-nowrap">
                  Date <ChevronUpDownIcon className="w-4 h-4 ml-1.5" />
                </div>
              </th>
              <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('forcastedDevelopment')}>
                <div className="flex items-center justify-center select-none whitespace-nowrap">
                  Forcasted development 3 years <ChevronUpDownIcon className="w-4 h-4 ml-1.5" />
                </div>
              </th>
              <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('interest')}>
                <div className="flex items-center justify-center select-none whitespace-nowrap">
                  Interest <ChevronUpDownIcon className="w-4 h-4 ml-1.5" />
                </div>
              </th>
              <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('value')}>
                <div className="flex items-center justify-center select-none whitespace-nowrap">
                  Value <ChevronUpDownIcon className="w-4 h-4 ml-1.5" />
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-slate-200">
            {filteredData.map((data, i) => (
              <tr className="bg-white" key={data.id}>
                <td className="w-4 py-4 pl-4">
                  <div className="flex items-center">
                    <input
                      id={data.id}
                      type="checkbox"
                      className="w-4 h-4 text-[#761185] bg-gray-100 border-gray-300 rounded focus:ring-fuchsia-500 focus:ring-2 cursor-pointer"
                      onChange={event => handleCheckboxChange(event, data)}
                      ref={el => (checkboxesRef.current[i] = el)}
                    />
                  </div>
                </td>
                <td className="pr-6 py-3">
                  <div className="flex items-center justify-center whitespace-nowrap">{data.id}</div>
                </td>
                <td className="px-6 py-3">
                  <div className="flex items-center justify-center whitespace-nowrap">{data.date}</div>
                </td>
                <td className="px-6 py-3">
                  <div className="flex items-center justify-center whitespace-nowrap">{data.forcastedDevelopment}%</div>
                </td>
                <td className="px-6 py-3">
                  <div className="flex items-center justify-center whitespace-nowrap">{data.interest}%</div>
                </td>
                <td className="px-6 py-3">
                  <div className="flex items-center justify-center whitespace-nowrap">{data.value}</div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div>
        <div className="flex justify-between items-center mt-10">
          <div className="text-gray-900">
            Total value: <span className="text-black">{totalValue} kr</span>
          </div>
          <div>
            <Button disabled={totalValue < 1}>Sell</Button>
          </div>
        </div>
      </div>
    </Panel>
  );
};

export default SellTable;
