import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LineChart, Line, XAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import Panel from '../Panel';
import PanelHeading from '../PanelHeading';
import PanelHeader from '../PanelHeader';
import { getDataStartingOneYearBack } from './utils';

const forecastData = t => [
  {
    name: `${t('month_jan')}`,
    forecasted: 0,
    withInterest: 0
  },
  {
    name: `${t('month_feb')}`,
    forecasted: 0,
    withInterest: 0
  },
  {
    name: `${t('month_mar')}`,
    forecasted: 0,
    withInterest: 0
  },
  {
    name: `${t('month_apr')}`,
    forecasted: 0,
    withInterest: 0
  },
  {
    name: `${t('month_may')}`,
    forecasted: 0,
    withInterest: 0
  },
  {
    name: `${t('month_jun')}`,
    forecasted: 0,
    withInterest: 0
  },
  {
    name: `${t('month_jul')}`,
    forecasted: 0,
    withInterest: 0
  },
  {
    name: `${t('month_aug')}`,
    forecasted: 0,
    withInterest: 0
  },
  {
    name: `${t('month_sep')}`,
    forecasted: 0,
    withInterest: 0
  },
  {
    name: `${t('month_oct')}`,
    forecasted: 0,
    withInterest: 0
  },
  {
    name: `${t('month_nov')}`,
    forecasted: 0,
    withInterest: 0
  },
  {
    name: `${t('month_dec')}`,
    forecasted: 0,
    withInterest: 0
  }
];

const InvestmentForecast = () => {
  const { t } = useTranslation();
  const [showInterest, setShowInterest] = useState(false);

  return (
    <Panel>
      <div className="flex flex-col items-end">
        <PanelHeader>
          <PanelHeading info={t('investmentForecastInfo')} title={t('interestCalculation')} />
          <div className="flex items-center">
            <span className="mr-2 text-sm font-medium text-gray-500">{t('compoundingInterest')}</span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                id="interest-checkbox"
                type="checkbox"
                value=""
                className="sr-only peer"
                checked={showInterest}
                onChange={() => setShowInterest(!showInterest)}
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#4f46e5]" />
            </label>
          </div>
        </PanelHeader>

        <div className="w-full">
          <ResponsiveContainer width="100%" height={200}>
            <LineChart data={getDataStartingOneYearBack(forecastData(t))}>
              <CartesianGrid strokeDasharray="3 6" />
              <XAxis dataKey="name" />
              <Tooltip />
              <Line type="monotone" name="Ränta" dataKey="forecasted" stroke="#14b8a6" strokeWidth={3} />
              {showInterest && (
                <Line name="Ränta-på-ränta" type="monotone" dataKey="withInterest" stroke="#4f46e5" strokeWidth={3} />
              )}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </Panel>
  );
};

export default InvestmentForecast;
