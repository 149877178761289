import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const YesNoRadio = ({ control, name, label, description, rules }) => {
  const { t } = useTranslation('profile');

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <div>
          <div className="text-sm">
            <span className="block text-sm leading-6 mb-2 text-gray-900">{label}</span>
            {description && <p className="mt-2 mb-2 font-normal text-gray-500">{description}</p>}
          </div>
          <div className="flex max-w-sm">
            <label
              htmlFor={`${name}-yes`}
              className={`block w-full -mr-px rounded-md rounded-br-none rounded-tr-none border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#761185] sm:text-sm sm:leading-6 hover:bg-[#f8f8ff] transition-colors cursor-pointer text-center ${
                field.value === 'yes' ? 'z-[1] bg-[#f7f7ff] ring-fuchsia-400' : ''
              }`}
            >
              <input type="radio" id={`${name}-yes`} {...field} value="yes" className="hidden" />
              {t('yes')}
            </label>
            <label
              htmlFor={`${name}-no`}
              className={`block w-full rounded-md rounded-bl-none rounded-tl-none border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#761185] sm:text-sm sm:leading-6 hover:bg-[#f8f8ff] transition-colors cursor-pointer text-center ${
                field.value === 'no' ? 'z-[1] bg-[#f7f7ff] ring-fuchsia-400' : ''
              }`}
            >
              <input type="radio" id={`${name}-no`} {...field} value="no" className="hidden" />
              {t('no')}
            </label>
          </div>
        </div>
      )}
    />
  );
};

export default YesNoRadio;
