import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu, Transition } from '@headlessui/react';
import { UserCircleIcon, UserIcon, ArrowLeftOnRectangleIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import Divider from '../Divider/Divider';
import ProfileInfo from './ProfileInfo';
import { logout } from '../../actions/auth';

const UserDropdown = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const isAuthenticated = useSelector(x => x.auth.isAuthenticated);

  const handleLogout = () => {
    dispatch(logout()).then(() => navigate('/'));
  };

  const isNotPersonalInfoPage = !location.pathname.includes('/personalinfo');

  return isAuthenticated ? (
    <Menu as="div" className="relative">
      <div className="flex items-center">
        <Menu.Button className="relative rounded-full focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
          <div className="flex items-center gap-2">
            <UserCircleIcon className="w-6 h-6" />
          </div>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-black ring-opacity-10 focus:outline-none">
          <Menu.Item>
            <ProfileInfo />
          </Menu.Item>
          <Divider className="m2 mx-4 light-gray" />
          {isNotPersonalInfoPage && (
            <>
              <Menu.Item>
                <Link
                  to="profile"
                  className="flex items-center px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
                >
                  <UserIcon className="mr-2 h-5 w-5 text-gray-600" aria-hidden="true" />
                  {t('myProfile')}
                </Link>
              </Menu.Item>
            </>
          )}
          <Menu.Item>
            <a
              href="mailto:support@jibba.se"
              className="flex items-center px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
            >
              <EnvelopeIcon className="mr-2 h-5 w-5 text-gray-600" aria-hidden="true" />
              {t('contactUs')}
            </a>
          </Menu.Item>

          <Divider className="m2 mx-4 light-gray" />

          <Menu.Item>
            <button
              onClick={handleLogout}
              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 text-left cursor-pointer hover:bg-gray-100"
            >
              <ArrowLeftOnRectangleIcon className="mr-2 h-5 w-5 text-gray-600" aria-hidden="true" />
              {t('signOut')}
            </button>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  ) : null;
};

export default UserDropdown;
