export const mapToSelect = (optionMap, t) =>
  Array.from(optionMap.entries()).map(([key, value]) => {
    const translatedLabel = t ? t(value) : value;
    return { value: key, label: translatedLabel };
  });

export const countries = new Map([
  ['AF', 'AF'],
  ['AX', 'AX'],
  ['AL', 'AL'],
  ['DZ', 'DZ'],
  ['AS', 'AS'],
  ['AD', 'AD'],
  ['AO', 'AO'],
  ['AI', 'AI'],
  ['AQ', 'AQ'],
  ['AG', 'AG'],
  ['AR', 'AR'],
  ['AM', 'AM'],
  ['AW', 'AW'],
  ['AU', 'AU'],
  ['AT', 'AT'],
  ['AZ', 'AZ'],
  ['BS', 'BS'],
  ['BH', 'BH'],
  ['BD', 'BD'],
  ['BB', 'BB'],
  ['BY', 'BY'],
  ['BE', 'BE'],
  ['BZ', 'BZ'],
  ['BJ', 'BJ'],
  ['BM', 'BM'],
  ['BT', 'BT'],
  ['BO', 'BO'],
  ['BQ', 'BQ'],
  ['BA', 'BA'],
  ['BW', 'BW'],
  ['BV', 'BV'],
  ['BR', 'BR'],
  ['IO', 'IO'],
  ['BN', 'BN'],
  ['BG', 'BG'],
  ['BF', 'BF'],
  ['BI', 'BI'],
  ['CV', 'CV'],
  ['KH', 'KH'],
  ['CM', 'CM'],
  ['CA', 'CA'],
  ['KY', 'KY'],
  ['CF', 'CF'],
  ['TD', 'TD'],
  ['CL', 'CL'],
  ['CN', 'CN'],
  ['CX', 'CX'],
  ['CC', 'CC'],
  ['CO', 'CO'],
  ['KM', 'KM'],
  ['CG', 'CG'],
  ['CD', 'CD'],
  ['CK', 'CK'],
  ['CR', 'CR'],
  ['HR', 'HR'],
  ['CU', 'CU'],
  ['CW', 'CW'],
  ['CY', 'CY'],
  ['CZ', 'CZ'],
  ['CI', 'CI'],
  ['DK', 'DK'],
  ['DJ', 'DJ'],
  ['DM', 'DM'],
  ['DO', 'DO'],
  ['EC', 'EC'],
  ['EG', 'EG'],
  ['SV', 'SV'],
  ['GQ', 'GQ'],
  ['ER', 'ER'],
  ['EE', 'EE'],
  ['SZ', 'SZ'],
  ['ET', 'ET'],
  ['FK', 'FK'],
  ['FO', 'FO'],
  ['FJ', 'FJ'],
  ['FI', 'FI'],
  ['FR', 'FR'],
  ['GF', 'GF'],
  ['PF', 'PF'],
  ['TF', 'TF'],
  ['GA', 'GA'],
  ['GM', 'GM'],
  ['GE', 'GE'],
  ['DE', 'DE'],
  ['GH', 'GH'],
  ['GI', 'GI'],
  ['GR', 'GR'],
  ['GL', 'GL'],
  ['GD', 'GD'],
  ['GP', 'GP'],
  ['GU', 'GU'],
  ['GT', 'GT'],
  ['GG', 'GG'],
  ['GN', 'GN'],
  ['GW', 'GW'],
  ['GY', 'GY'],
  ['HT', 'HT'],
  ['HM', 'HM'],
  ['VA', 'VA'],
  ['HN', 'HN'],
  ['HK', 'HK'],
  ['HU', 'HU'],
  ['IS', 'IS'],
  ['IN', 'IN'],
  ['ID', 'ID'],
  ['IR', 'IR'],
  ['IQ', 'IQ'],
  ['IE', 'IE'],
  ['IM', 'IM'],
  ['IL', 'IL'],
  ['IT', 'IT'],
  ['JM', 'JM'],
  ['JP', 'JP'],
  ['JE', 'JE'],
  ['JO', 'JO'],
  ['KZ', 'KZ'],
  ['KE', 'KE'],
  ['KI', 'KI'],
  ['KP', 'KP'],
  ['KR', 'KR'],
  ['KW', 'KW'],
  ['KG', 'KG'],
  ['LA', 'LA'],
  ['LV', 'LV'],
  ['LB', 'LB'],
  ['LS', 'LS'],
  ['LR', 'LR'],
  ['LY', 'LY'],
  ['LI', 'LI'],
  ['LT', 'LT'],
  ['LU', 'LU'],
  ['MO', 'MO'],
  ['MG', 'MG'],
  ['MW', 'MW'],
  ['MY', 'MY'],
  ['MV', 'MV'],
  ['ML', 'ML'],
  ['MT', 'MT'],
  ['MH', 'MH'],
  ['MQ', 'MQ'],
  ['MR', 'MR'],
  ['MU', 'MU'],
  ['YT', 'YT'],
  ['MX', 'MX'],
  ['FM', 'FM'],
  ['MD', 'MD'],
  ['MC', 'MC'],
  ['MN', 'MN'],
  ['ME', 'ME'],
  ['MS', 'MS'],
  ['MA', 'MA'],
  ['MZ', 'MZ'],
  ['MM', 'MM'],
  ['NA', 'NA'],
  ['NR', 'NR'],
  ['NP', 'NP'],
  ['NL', 'NL'],
  ['NC', 'NC'],
  ['NZ', 'NZ'],
  ['NI', 'NI'],
  ['NE', 'NE'],
  ['NG', 'NG'],
  ['NU', 'NU'],
  ['NF', 'NF'],
  ['MP', 'MP'],
  ['NO', 'NO'],
  ['OM', 'OM'],
  ['PK', 'PK'],
  ['PW', 'PW'],
  ['PS', 'PS'],
  ['PA', 'PA'],
  ['PG', 'PG'],
  ['PY', 'PY'],
  ['PE', 'PE'],
  ['PH', 'PH'],
  ['PN', 'PN'],
  ['PL', 'PL'],
  ['PT', 'PT'],
  ['PR', 'PR'],
  ['QA', 'QA'],
  ['MK', 'MK'],
  ['RO', 'RO'],
  ['RU', 'RU'],
  ['RW', 'RW'],
  ['RE', 'RE'],
  ['BL', 'BL'],
  ['SH', 'SH'],
  ['KN', 'KN'],
  ['LC', 'LC'],
  ['MF', 'MF'],
  ['PM', 'PM'],
  ['VC', 'VC'],
  ['WS', 'WS'],
  ['SM', 'SM'],
  ['ST', 'ST'],
  ['SA', 'SA'],
  ['SN', 'SN'],
  ['RS', 'RS'],
  ['SC', 'SC'],
  ['SL', 'SL'],
  ['SG', 'SG'],
  ['SX', 'SX'],
  ['SK', 'SK'],
  ['SI', 'SI'],
  ['SB', 'SB'],
  ['SO', 'SO'],
  ['ZA', 'ZA'],
  ['GS', 'GS'],
  ['SS', 'SS'],
  ['ES', 'ES'],
  ['LK', 'LK'],
  ['SD', 'SD'],
  ['SR', 'SR'],
  ['SJ', 'SJ'],
  ['SE', 'SE'],
  ['CH', 'CH'],
  ['SY', 'SY'],
  ['TW', 'TW'],
  ['TJ', 'TJ'],
  ['TZ', 'TZ'],
  ['TH', 'TH'],
  ['TL', 'TL'],
  ['TG', 'TG'],
  ['TK', 'TK'],
  ['TO', 'TO'],
  ['TT', 'TT'],
  ['TN', 'TN'],
  ['TR', 'TR'],
  ['TM', 'TM'],
  ['TC', 'TC'],
  ['TV', 'TV'],
  ['UG', 'UG'],
  ['UA', 'UA'],
  ['AE', 'AE'],
  ['GB', 'GB'],
  ['US', 'US'],
  ['UM', 'UM'],
  ['UY', 'UY'],
  ['UZ', 'UZ'],
  ['VU', 'VU'],
  ['VE', 'VE'],
  ['VN', 'VN'],
  ['VG', 'VG'],
  ['VI', 'VI'],
  ['WF', 'WF'],
  ['EH', 'EH'],
  ['YE', 'YE'],
  ['ZM', 'ZM'],
  ['ZW', 'ZW']
]);

export const booleanOptions = new Map([
  [true, 'yes'],
  [false, 'no']
]);

export const occupationOptions = new Map([
  ['companyWithLessThanTenEmployees', 'companyWithLessThanTenEmployees'],
  ['companyWithTenToHundredEmployees', 'companyWithTenToHundredEmployees'],
  ['companyWithMoreThanHundredEmployees', 'companyWithMoreThanHundredEmployees'],
  ['ownCompanyPersonal', 'ownCompanyPersonal'],
  ['ownCompany', 'ownCompany'],
  ['fullTimeStudent', 'fullTimeStudent'],
  ['partTimeStudent', 'partTimeStudent'],
  ['retired', 'retired'],
  ['jobSeeker', 'jobSeeker'],
  ['unemployed', 'unemployed'],
  ['otherOccupation', 'otherOccupation']
]);

export const incomeOptions = new Map([
  ['under30K', 'under30K'],
  ['35To50K', '35To50K'],
  ['50To75K', '50To75K'],
  ['75To100K', '75To100K'],
  ['over100K', 'over100K']
]);

export const incomeTypeOptions = new Map([
  ['salary', 'salary'],
  ['pension', 'pension'],
  ['studentFunding', 'studentFunding'],
  ['welfare', 'welfare'],
  ['savingOrInvestments', 'savingOrInvestments'],
  ['distributedProfits', 'distributedProfits'],
  ['gifts', 'gifts'],
  ['gambling', 'gambling'],
  ['currencyTrading', 'currencyTrading']
]);

export const timeHorizonOptions = new Map([
  ['1To3y', '1To3y'],
  ['3to10y', '3to10y'],
  ['over10y', 'over10y']
]);

export const depositFrequencyOptions = new Map([
  ['severalPerMonth', 'severalPerMonth'],
  ['onceEveryMonth', 'onceEveryMonth'],
  ['everyFewMonths', 'everyFewMonths'],
  ['oncePerYearOrLess', 'oncePerYearOrLess']
]);

const inputOptions = {
  booleanOptions,
  countries,
  depositFrequencyOptions,
  incomeOptions,
  incomeTypeOptions,
  occupationOptions,
  timeHorizonOptions
};

export default inputOptions;
