import { LOGOUT_SUCCESS } from '../actions/auth';
import { ADD_INVESTMENT_SUCCESS, GET_TOTAL_INVESTED_AMOUNT, GET_INVESTMENT_HISTORY } from '../actions/investment';

const initialState = {
  totalInvestedAmount: null,
  investmentHistory: null
};

export default function investmentReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_INVESTMENT_SUCCESS:
      return {
        ...state,
        totalInvestedAmount: (state.totalInvestedAmount || 0) + payload.amount,
        investmentHistory: [payload, ...(state.investmentHistory || [])]
      };
    case GET_TOTAL_INVESTED_AMOUNT:
      return { ...state, totalInvestedAmount: payload };
    case GET_INVESTMENT_HISTORY:
      return { ...state, investmentHistory: payload };
    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
}
