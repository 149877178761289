import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { getActiveSession } from '../../actions/auth';
import Spinner from '../Spinner';

const PublicRoute = ({ element }) => {
  const { activeSessionChecked, isAuthenticated } = useSelector(x => x.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isAuthenticated && !activeSessionChecked) {
      dispatch(getActiveSession());
    }
  }, [isAuthenticated, activeSessionChecked, dispatch]);
  if (!activeSessionChecked) {
    return <Spinner></Spinner>;
  } else if (!isAuthenticated) {
    return element;
  } else return <Navigate to="/overview" replace />;
};

export default PublicRoute;
