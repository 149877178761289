import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeading } from './Modal';
import Button from './Button';
import { TextInput } from './Inputs';
import { useDispatch, useSelector } from 'react-redux';
import { notifyBeforeLaunch, saveInvestmentFormInput } from '../actions/investment';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

const InvestmentForm = ({ showModal, setShowModal }) => {
  const { t } = useTranslation('invest');
  const { t: commonT } = useTranslation('common');
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { userInfo } = useSelector(state => state.user);

  const handleAmountChange = event => {
    try {
      const newAmount = Number(event.target.value);
      setAmount(newAmount);
    } catch (_) {}
  };

  useEffect(() => {
    if (!showModal && amount > 0) {
      dispatch(saveInvestmentFormInput(amount)).then(() => setAmount(0));
    }
  }, [showModal, amount, dispatch]);

  const _notifyBeforeLaunch = async () => {
    setError(null);
    setLoading(true);
    if (userInfo.hasClickedNotifyBeforeLaunch) return setTimeout(() => setLoading(false), 500);
    try {
      await dispatch(notifyBeforeLaunch());
    } catch (err) {
      setError(t('somethingWentWrong'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal showModal={showModal} setShowModal={setShowModal}>
      <ModalBody>
        <ModalHeading>{t('directDeposit')}</ModalHeading>
        <div className="max-w-xs mx-auto">
          <div className="flex gap-4 justify-center items-center mb-8 mt-16">
            <TextInput
              large
              className="!w-60"
              id="amount"
              name="amount"
              placeholder="10000"
              onChange={handleAmountChange}
              register={() => {}}
            />
            <p className="text-gray-600 text-xl leading-5 mt-2">SEK</p>
          </div>
          <div className="flex column gap-2 mt-7">
            <Button secondary disabled>{`${t('makeDeposit')} (${t('comingSoon')})`}</Button>
            <Button
              loading={loading}
              disabled={userInfo.hasClickedNotifyBeforeLaunch}
              onClick={() => _notifyBeforeLaunch()}
              className="flex items-center justify-center"
            >
              <span>{t('notifyAtLaunch')}</span>
              {userInfo.hasClickedNotifyBeforeLaunch && <CheckCircleIcon className="h-5 ml-2 text-green-500" />}
            </Button>
            {error && <p className="error-text">{commonT('somethingWentWrong')}</p>}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default InvestmentForm;
