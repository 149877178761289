import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Panel from '../../components/Panel';
import Spinner from '../../components/Spinner';
import { validateStateId, verifyBankAccount } from '../../actions/bankAccount';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const Countdown = () => {
  const [secondsLeft, setSecondsLeft] = useState(4);
  const { t } = useTranslation('common');

  const interval = setInterval(() => {
    if (secondsLeft > 0) {
      setSecondsLeft(secondsLeft - 1);
    } else {
      clearInterval(interval);
      return window.close();
    }
  }, 1000);

  return <p>{`${t('thisWindowWillCloseIn')} ${secondsLeft}s`}</p>;
};

const VerificationConfirmation = () => {
  const { t } = useTranslation('common');
  const { t: profileT } = useTranslation('profile');
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const reportId = searchParams.get('account_verification_report_id');
  const stateId = searchParams.get('state');
  const tinkError = searchParams.get('error');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (stateId && reportId) {
      dispatch(validateStateId(stateId))
        .then(() => dispatch(verifyBankAccount(reportId)))
        .catch(() => {
          setError(t('somethingWentWrong'));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [reportId, stateId, t, dispatch]);

  useEffect(() => {
    if (tinkError) {
      setError(tinkError);
    }
  }, [tinkError]);

  return (
    <Panel>
      {error && (
        <div className="text-center">
          <p className="error-text">{error}</p>
          <Countdown />
        </div>
      )}
      {loading && !error && <Spinner></Spinner>}
      {!loading && !error && (
        <div className="text-center">
          <p>{profileT('yourBankAccountHasBeenAdded')}</p>
          <Countdown />
        </div>
      )}
    </Panel>
  );
};

export default VerificationConfirmation;
