import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { useLanguage } from '../../i18n/language';
import { signup } from '../../actions/auth';
import Divider from '../../components/Divider/Divider';

const Signup = () => {
  const { register, handleSubmit, setError, formState, clearErrors, getValues } = useForm({ mode: 'onSubmit' });
  const { t: loginT } = useTranslation('login');
  const { t: commonT } = useTranslation('common');
  const language = useLanguage();

  const handleSignup = async values => {
    try {
      await signup({ email: values.email, language });
    } catch (err) {
      setError('_error', { type: 'custom', message: commonT('somethingWentWrong') });
    }
  };

  const onSubmit = event => {
    clearErrors();
    handleSubmit(handleSignup)(event);
  };

  return (
    <div className="wrapper">
      <div className="max-w-md container-div">
        <div className="header-div">
          <h1 className="header-1">{loginT('createYourAccount')}</h1>
          <p className="get-ready-text">{loginT('getReady')}</p>
        </div>
        {!formState.isSubmitSuccessful && (
          <form className="full-width-column" onSubmit={onSubmit}>
            <div className="column">
              <label className="form-label">{commonT('emailAddress')}</label>
              <input
                className="text-input"
                {...register('email', { required: commonT('requiredField') })}
                type="email"
                placeholder={commonT('enterYourEmail')}
              />
            </div>
            {formState?.errors?._error?.message && (
              <p className="error-text form-error">{formState.errors._error.message}</p>
            )}
            <button type="submit" className="submit-button">
              {commonT('signup')}
            </button>
            <Divider className={'m4'} />
            <div className="flex text-slate-400 gap-1 justify-center">
              <p>{loginT('alreadyHaveAnAccount')}</p>
              <Link to="/login" className="text-slate-500 hover:text-[#761185]">
                {commonT('login')}
              </Link>
            </div>
          </form>
        )}
        {formState.isSubmitSuccessful && (
          <div className="full-width-column">
            <div className="bg-green-50 py-3 rounded">
              <div className="flex flex-row justify-evenly items-baseline">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-green-500 px-1 relative top-1 text-[1.3rem]"
                  aria-hidden="true"
                />
                <p className="text-gray-500 w-5/6 font-thin text-lg">
                  <Trans
                    i18nKey="signupEmailSent"
                    ns={'login'}
                    components={{
                      greenSpan: <span className="text-green-600" />,
                      email: getValues('email')
                    }}
                  ></Trans>
                  <br />
                  {loginT('linkWillExpire')}
                </p>
              </div>
            </div>
            <Divider className={'m4-6'} />
            <p className="text-gray-400 text-center">{loginT('ifNotReceivedEmail')}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Signup;
