import {
  GET_ACTIVE_SESSION_SUCCESS,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_FAIL
} from '../actions/auth.js';

export default function authReducer(state = { isAuthenticated: false, activeSessionChecked: false }, action) {
  const { type } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isAuthenticated: false
      };
    case LOGOUT_SUCCESS:
      return {
        isAuthenticated: false,
        activeSessionChecked: true
      };
    case SET_PASSWORD_SUCCESS:
      return {
        ...state,
        isAuthenticated: true
      };
    case SET_PASSWORD_FAIL:
      return {
        ...state,
        isAuthenticated: false
      };
    case GET_ACTIVE_SESSION_SUCCESS:
      return {
        isAuthenticated: true,
        activeSessionChecked: true
      };
    default:
      return state;
  }
}
