import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useLanguage } from '../../i18n/language';
import { sendResetPasswordEmail } from '../../actions/auth';
import Divider from '../../components/Divider/Divider';

const ForgotPassword = () => {
  const { register, handleSubmit, setError, formState, clearErrors, getValues } = useForm();
  const { t: commonT } = useTranslation('common');
  const { t: loginT } = useTranslation('login');
  const language = useLanguage();

  const handleSendEmail = async values => {
    try {
      await sendResetPasswordEmail({ email: values.email, language });
    } catch (err) {
      setError('_error', { type: 'custom', message: commonT('somethingWentWrong') });
    }
  };

  const onSubmit = event => {
    clearErrors();
    handleSubmit(handleSendEmail)(event);
  };

  return (
    <div className="wrapper">
      <div className="max-w-md container-div">
        {!formState.isSubmitSuccessful && (
          <div className="header-div">
            <h1 className="header-1">{loginT('forgotYourPassword')}</h1>
            <p className="explainer-text">{loginT('sendInstructionsToResetPassword')}</p>
          </div>
        )}
        {!formState.isSubmitSuccessful && (
          <form className="full-width-column" onSubmit={onSubmit}>
            <div className="column">
              <label className="form-label">{commonT('emailAddress')}</label>
              <input
                className="text-input"
                {...register('email', { required: commonT('requiredField') })}
                type="email"
                placeholder={commonT('enterYourEmail')}
              />
            </div>
            {formState?.errors?._error?.message && (
              <p className="error-text form-error">{formState.errors._error.message}</p>
            )}
            <button type="submit" className="submit-button">
              {commonT('send')}
            </button>
          </form>
        )}
        {formState.isSubmitSuccessful && (
          <div className="full-width-column">
            <div className="header-div">
              <h1 className="header-1">{loginT('forgotYourPassword')}</h1>
            </div>
            <div className="column reset-password-sent-container">
              <p>
                <Trans
                  i18nKey="resetPasswordEmailSent"
                  ns={'login'}
                  components={{ greenSpan: <span className="green-text" />, email: getValues('email') }}
                ></Trans>
              </p>
              <p>{loginT('ifNotReceivedEmail')}</p>
            </div>
            <Divider className={'m4'} />
            <p className="light-gray-centered">
              {loginT('rememberThePassword')}{' '}
              <Link className="underlined-link" to="/login">
                {loginT('login')}
              </Link>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
