import TopNavigation from '../TopNavigation/TopNavigation';
import SideNavigation from '../SideNavigation/SideNavigation';
import ScrollToTop from '../ScrollToTop';

const Layout = ({ children }) => {
  return (
    <div>
      <ScrollToTop />
      <TopNavigation />
      <div className="relative flex justify-start">
        <SideNavigation />
        <main className="w-full pb-[6.5rem] md:pb-6 px-6 pt-8 md:px-10 md:pt-10 2xl:px-12 2xl:pt-14 2xl:max-w-[74rem] 2xl:mx-auto">
          {children}
        </main>
      </div>
    </div>
  );
};

export default Layout;
