import { useTranslation } from 'react-i18next';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const getDecimals = value => {
  if (value.toFixed(1).endsWith('0')) {
    return value.toFixed(0);
  }
  return value.toFixed(1);
};

const InvestChart = ({ data }) => {
  const { t } = useTranslation('invest');
  const tickFormatter = val => {
    if (val > 999999999999) {
      return `${getDecimals(val / 1000000000000)}T`;
    } else if (val > 999999999) {
      return `${getDecimals(val / 1000000000)}B`;
    } else if (val > 999999) {
      return `${getDecimals(val / 1000000)}M`;
    } else if (val > 999) {
      return `${getDecimals(val / 1000)}K`;
    } else if (!val || val === '0') {
      return '0';
    } else {
      return val.toFixed(0);
    }
  };

  return (
    <div className="w-full">
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          data={data}
          margin={{
            top: 30
          }}
        >
          <defs>
            <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#4ade80" stopOpacity={0.9} />
              <stop offset="95%" stopColor="#4ade80" stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year" dy={10} />
          <YAxis tickFormatter={tickFormatter} tickMargin={10} />
          <Tooltip />
          <Area
            type="monotone"
            name={t('value')}
            dataKey="value"
            stroke="#4ade80"
            fillOpacity={1}
            fill="url(#colorValue)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default InvestChart;
