import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { setPassword, checkForgotPasswordLink } from '../actions/auth';
import { passwordValidator, matchesPassword } from '../utils/inputValidators';
import Password from '../components/Inputs/Password';

const linkStates = { loading: 'loading', valid: 'valid', invalid: 'invalid' };

const ChangePassword = () => {
  const { register, handleSubmit, setError, formState, clearErrors, watch } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange'
  });
  const { t: loginT } = useTranslation('login');
  const { t: commonT } = useTranslation('common');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [linkState, setLinkState] = useState(linkStates.loading);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const userId = searchParams.get('id');
  const firstPassword = watch('password');

  useEffect(() => {
    if (token && userId) {
      checkForgotPasswordLink({ token, userId })
        .then(() => setLinkState(linkStates.valid))
        .catch(() => setLinkState(linkStates.invalid));
    }
  }, [token, userId]);

  const handleChangePassword = async values => {
    try {
      await setPassword({ userId, password: values.password })(dispatch);
      navigate('/');
    } catch (err) {
      setError('_error', { type: 'custom', message: commonT('somethingWentWrong') });
    }
  };

  const onSubmit = event => {
    clearErrors();
    handleSubmit(handleChangePassword)(event);
  };

  return (
    <div className="wrapper">
      {linkState === linkStates.valid && (
        <div className="container-div">
          <div className="header-div">
            <h1 className="header-1">{loginT('changePassword')}</h1>
          </div>
          <form className="full-width-column" onSubmit={onSubmit}>
            <Password
              label={loginT('newPassword')}
              placeholder={loginT('enterYourPassword')}
              errors={formState.errors?.password?.message}
              register={{ ...register('password', { validate: { passwordValidator } }) }}
            />
            <Password
              label={loginT('confirmPassword')}
              placeholder={loginT('confirmYourPassword')}
              errors={formState.errors?.confirmPassword?.message}
              register={{
                ...register('confirmPassword', { validate: { matchesPassword: matchesPassword(firstPassword) } })
              }}
            />
            {formState?.errors?._error?.message && (
              <p className="error-text form-error">{formState.errors._error.message}</p>
            )}
            <button type="submit" className="submit-button">
              {loginT('changePassword')}
            </button>
          </form>
        </div>
      )}
      {linkState === linkStates.invalid && (
        <div className="container-div">
          <div className="header-div">
            <h1 className="header-1">{commonT('invalidLink')}</h1>
            <p className="explainer-text">{commonT('linkIsExpired')}</p>
          </div>
        </div>
      )}
      {linkState === linkStates.loading && (
        <div className="container-div">
          <div className="header-div">
            <FontAwesomeIcon icon={faSpinner} spin={true} className="spinner"></FontAwesomeIcon>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChangePassword;
