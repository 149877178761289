import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import PublicRoute from './components/LimitedRoutes/PublicRoute';
import PrivateRoute from './components/LimitedRoutes/PrivateRoute';
import Market from './pages/market/market';
import Overview from './pages/overview';
import Earnings from './pages/earnings';
import Invest from './pages/invest';
import Profile from './pages/profile/profile';
import Signup from './pages/signup/signup';
import PersonalInfo from './pages/personalInfo';
import ForgotPassword from './pages/forgotPassword/forgotPassword';
import SetPassword from './pages/setPassword';
import ChangePassword from './pages/changePassword';
import Login from './pages/login/login';
import General from './pages/profile/general';
import Questions from './pages/profile/questions';
import Account from './pages/profile/account';
import VerificationConfirmation from './pages/profile/verificationConfirmation';

const App = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<PublicRoute element={<Navigate to="/login" replace />} />} />
          <Route path="login" element={<PublicRoute element={<Login />} />} />
          <Route path="overview" element={<PrivateRoute element={<Overview />} />} />
          <Route path="market" element={<PrivateRoute element={<Market />} />} />
          <Route path="earnings" element={<PrivateRoute element={<Earnings />} />} />
          <Route path="invest" element={<PrivateRoute element={<Invest />} />} />
          <Route path="profile" element={<PrivateRoute element={<Profile />} />}>
            <Route index element={<PrivateRoute element={<Navigate replace to="/profile/general" />} />} />
            <Route path="general" element={<PrivateRoute element={<General />} />} />
            <Route path="questions" element={<PrivateRoute element={<Questions />} />} />
            <Route path="account" element={<PrivateRoute element={<Account />} />} />
            <Route path="*" element={<div className="text-center">404 Not Found</div>} />
          </Route>
          <Route path="personalinfo" element={<PrivateRoute element={<PersonalInfo />} />} />
          <Route path="verificationconfirmation" element={<PrivateRoute element={<VerificationConfirmation />} />} />
          <Route path="signup" element={<PublicRoute element={<Signup />} />} />
          <Route path="forgotpassword" element={<PublicRoute element={<ForgotPassword />} />} />
          <Route path="setpassword" element={<PublicRoute element={<SetPassword />} />} />
          <Route path="changepassword" element={<PublicRoute element={<ChangePassword />} />} />
          <Route path="*" element={<div className="text-center">404 Not Found</div>} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default App;
