import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { init } from '@sentry/react';
import './styles/globals.css';
import App from './app';
import store from './store';
import { initializeI18n } from './i18n/i18n';

if (['production', 'test'].includes(import.meta.env.VITE_ENV)) {
  init({
    dsn: import.meta.env.VITE_SENTRY_DSN
  });
}
const language = window.localStorage.getItem('language');
initializeI18n(language || 'sv');

let hiddenStartTime = null;
document.addEventListener('visibilitychange', function () {
  if (document.hidden) {
    hiddenStartTime = Date.now();
  } else {
    if (hiddenStartTime !== null) {
      const elapsedTime = Date.now() - hiddenStartTime;
      if (elapsedTime >= 1000 * 60 * 60) {
        window.location.reload();
      }
      hiddenStartTime = null;
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense>
    <Provider store={store}>
      <App />
    </Provider>
  </Suspense>
);
