import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Panel from './Panel';
import InvestChart from './Charts/InvestChart';
import { TextInput, Label } from './Inputs';
import SectionHeading from './SectionHeading';
import useIsMobile from '../hooks/useIsMobile';

const InvestForm = ({ investedAmount }) => {
  const { t } = useTranslation();
  const { register } = useForm();
  const [years, setYears] = useState(1);
  const [investmentAmount, setInvestmentAmount] = useState(0);
  const [monthlySavings, setMonthlySavings] = useState(0);
  const [expectedValue, setExpectedValue] = useState(0);
  const isMobile = useIsMobile();

  const investChartDefault = [
    {
      year: '',
      value: 0
    },
    {
      year: `${t('year')} 1`,
      value: 0
    }
  ];

  const [investChartData, setInvestChartData] = useState(investChartDefault);

  useEffect(() => {
    if (investedAmount) {
      setInvestmentAmount(investedAmount);
    }
  }, [investedAmount]);

  useEffect(() => {
    const calculateCompoundInterest = () => {
      const annualInterestRate = 9.4;
      const interestRate = annualInterestRate / 100;
      const monthlyInterestRate = interestRate / 12;
      const numMonths = years * 12;

      let totalAmount = investmentAmount;
      const yearlyAmounts = [totalAmount];

      for (let i = 1; i <= numMonths; i++) {
        const monthlyInterest = (totalAmount + monthlySavings) * monthlyInterestRate;
        totalAmount = totalAmount + monthlySavings + monthlyInterest;

        if (i % 12 === 0) {
          yearlyAmounts.push(totalAmount);
        }
      }

      return yearlyAmounts.map((amount, i) => ({ year: i > 0 ? `${t('year')} ${i}` : '', value: Math.round(amount) }));
    };
    const chartData = calculateCompoundInterest();

    setExpectedValue(
      (Math.round(chartData[chartData.length - 1].value * 100) / 100).toLocaleString('sv-SE', { useGrouping: true })
    );
    setInvestChartData(chartData);
  }, [investmentAmount, monthlySavings, years, t]);

  const handleYearsInput = event => {
    setYears(Number(event.currentTarget.value));
  };

  const handleinvestmentAmountInput = event => {
    event.preventDefault();
    if (Number(event.currentTarget.value) > 999999999) return;
    setInvestmentAmount(Number(event.currentTarget.value));
  };

  const handleMonthlySavingsInput = event => {
    event.preventDefault();
    if (Number(event.currentTarget.value) > 9999999) return;
    setMonthlySavings(Number(event.currentTarget.value));
  };

  const getYearsLeft = years => {
    const YEAR_VALUES = {
      0: '0%',
      1: '1%',
      2: '11%',
      3: '22%',
      4: '32%',
      5: '43%',
      6: '54%',
      7: '65%',
      8: '75%',
      9: '86%',
      10: '95%'
    };

    return YEAR_VALUES[years];
  };

  return (
    <div>
      <SectionHeading heading={t('simulateYourGrowth')} />
      <Panel>
        <div className="flex">
          <div className="w-full md:mr-8">
            <div className="grid grid-cols-1 gap-x-10 gap-y-12 md:grid-cols-6">
              <div className="col-span-3">
                <TextInput
                  name="startAmount"
                  id="startAmount"
                  type="number"
                  label={t('investmentAmount')}
                  placeholder={0}
                  onChange={handleinvestmentAmountInput}
                  value={investmentAmount || ''}
                  register={register}
                />
              </div>

              <div className="col-span-3">
                <TextInput
                  name="monthlySavings"
                  id="monthlySavings"
                  type="number"
                  label={t('monthlySavings')}
                  placeholder={0}
                  onChange={handleMonthlySavingsInput}
                  value={monthlySavings || ''}
                  register={register}
                />
              </div>

              <div className="col-span-3 md:col-span-full">
                <Label htmlFor="steps-range">{t('numberOfYears')}</Label>
                <div className="relative mt-2">
                  <input
                    id="steps-range"
                    type="range"
                    min="1"
                    max="10"
                    step="1"
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-green-500"
                    value={years}
                    onChange={handleYearsInput}
                  />
                  <div className="relative pt-5">
                    <span className="absolute -bottom-4 text-xl " style={{ left: getYearsLeft(years) }}>
                      {years}
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-span-3 md:col-span-full">
                <p className="text-gray-600 font-semibold mb-2">{t('expectedValuePortfolio')}</p>
                <p className="text-green-500 font-semibold text-2xl md:text-[32px] mb-4 md:mb-0">
                  {expectedValue} {'kr'}
                </p>
              </div>
            </div>
            {isMobile && <InvestChart data={investChartData} />}
          </div>
          {!isMobile && <InvestChart data={investChartData} />}
        </div>
      </Panel>
    </div>
  );
};

export default InvestForm;
