const InvestmentProgress = () => {
  const investments = [
    { name: 'Investment 1', value: 35 },
    { name: 'Investment 2', value: 75 }
  ];

  return (
    <>
      {investments.map(({ name, value }) => (
        <div key={name} className="flex justify-between items-center py-6">
          <div className="text-gray-600">{name}</div>
          <div className="w-full max-w-[5rem] bg-gray-200 rounded-full h-2">
            <div className="bg-[#761185] h-2 rounded-full" style={{ width: `${value}%` }}></div>
          </div>
        </div>
      ))}
    </>
  );
};

export default InvestmentProgress;
