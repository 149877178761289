import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { getActiveSession } from '../../actions/auth';
import Spinner from '../Spinner';

const PrivateRoute = ({ element }) => {
  const { activeSessionChecked, isAuthenticated } = useSelector(x => x.auth);
  const { userInfo } = useSelector(x => x.user);
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isAuthenticated && !activeSessionChecked) {
      dispatch(getActiveSession());
    }
  }, [isAuthenticated, activeSessionChecked, dispatch]);
  const isPersonalInfoPage = location.pathname === '/personalinfo';

  if (
    isAuthenticated &&
    ((userInfo?.hasCompletedKYC && !isPersonalInfoPage) || (!userInfo?.hasCompletedKYC && isPersonalInfoPage))
  ) {
    return element;
  } else if (isAuthenticated && !userInfo?.hasCompletedKYC) {
    return <Navigate to="/personalinfo" replace />;
  } else if (!activeSessionChecked) {
    return <Spinner></Spinner>;
  } else return <Navigate to="/" replace />;
};

export default PrivateRoute;
