import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EarningCards from '../components/EarningCards';
import Button from '../components/Button';
import OverviewBarChart from '../components/Charts/OverviewBarChart';
import FadeIn from '../components/FadeIn';
import useInvestmentDetails from '../hooks/useInvestmentDetails';
import { useSelector } from 'react-redux';

export default function Overview() {
  const { t } = useTranslation();
  const { totalCapitalFormatted, investedAmountFormatted, accumulatedInterestFormatted, investedAmount } =
    useInvestmentDetails();
  const { firstName } = useSelector(state => state.user.userInfo);
  const [invested, setInvested] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (investedAmount && investedAmount > 0) {
      setInvested(true);
    }
  }, [investedAmount]);

  return (
    <FadeIn>
      <div>
        <div className="relative isolate">
          <div>
            <div>
              <h1 className="text-[#2D3648] font-spaceGrotesk text-4xl lg:text-5xl font-bold mb-6">
                {firstName ? `${t('greetingWithName')}, ${firstName}!` : `${t('greeting')}`}
              </h1>
              <p className="max-w-[44rem] text-lg leading-8 text-gray-600">
                {invested ? t('investmentOverview') : t('startYourInvestments')}
              </p>

              {invested ? (
                <div>
                  <EarningCards
                    totalCapitalFormatted={totalCapitalFormatted}
                    investedAmountFormatted={investedAmountFormatted}
                    accumulatedInterestFormatted={accumulatedInterestFormatted}
                  />
                  <OverviewBarChart />
                </div>
              ) : (
                <div className="mt-10 flex items-center gap-x-6">
                  <Button
                    onClick={() => {
                      setInvested(true);
                      navigate('/invest');
                    }}
                  >
                    {t('startInvesting')}
                  </Button>
                  {/* <button className="font-semibold leading-6 text-gray-900 tracking-wide hover:text-gray-700">
                    {t('learnMore')} <span aria-hidden="true">→</span>
                  </button> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </FadeIn>
  );
}
