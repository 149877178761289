import { useTranslation } from 'react-i18next';
import FadeIn from '../components/FadeIn';
import InvestTable from '../components/InvestTable';
import InvestForm from '../components/InvestForm';
import InvestHistory from '../components/InvestHistory';
import SectionHeading from '../components/SectionHeading';
import useInvestmentDetails from '../hooks/useInvestmentDetails';
import Button from '../components/Button';
import { useState } from 'react';
import InvestmentForm from '../components/InvestmentForm';
// import InvestBtnPreLaunch from '../components/InvestBtnPreLaunch';

const Invest = () => {
  const { t } = useTranslation();
  const { investedAmountFormatted, accumulatedInterestFormatted, investments, investedAmount } = useInvestmentDetails();
  const [showModal, setShowModal] = useState(false);

  return (
    <FadeIn>
      <div>
        <SectionHeading heading={t('invest')}>
          <Button small onClick={() => setShowModal(true)} className="flex justify-center">
            {t('newInvestment')}
          </Button>
          <InvestmentForm showModal={showModal} setShowModal={setShowModal} />
        </SectionHeading>

        <div className="flex flex-col gap-10">
          <InvestTable
            investedAmountFormatted={investedAmountFormatted}
            accumulatedInterestFormatted={accumulatedInterestFormatted}
          />
          <InvestForm investedAmount={investedAmount} />
          <InvestHistory investments={investments} />
        </div>
      </div>
    </FadeIn>
  );
};

export default Invest;
