import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from '../../components/Button';
import Form from '../../components/Form';
import Panel from '../../components/Panel';
import { TextInput, Select } from '../../components/Inputs';
import { countries, mapToSelect } from '../../utils/options';
import { saveUserInfo } from '../../actions/user';
import { useDispatch, useSelector } from 'react-redux';

const _getValueFromUserInfo = (userInfo, fieldName, t) => {
  const value = userInfo?.address?.[fieldName] || userInfo?.[fieldName];
  if ([null, undefined].includes(value)) return fieldName === 'country' ? null : '';
  if (fieldName === 'country') return { value, label: t(countries.get(value)) };
  return value;
};

const General = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const [error, setError] = useState(false);

  return (
    <Panel>{error ? <p>{t('somethingWentWrong')}</p> : <Content dispatch={dispatch} setError={setError} />}</Panel>
  );
};

const Content = ({ dispatch, setError }) => {
  const { t } = useTranslation('profile');
  const userInfo = useSelector(state => state.user.userInfo);

  const { control, formState, handleSubmit, register, reset } = useForm({
    defaultValues: {
      firstName: _getValueFromUserInfo(userInfo, 'firstName'),
      lastName: _getValueFromUserInfo(userInfo, 'lastName'),
      email: _getValueFromUserInfo(userInfo, 'email'),
      phone: _getValueFromUserInfo(userInfo, 'phone'),
      country: _getValueFromUserInfo(userInfo, 'country', t),
      city: _getValueFromUserInfo(userInfo, 'city'),
      street: _getValueFromUserInfo(userInfo, 'street'),
      zipCode: _getValueFromUserInfo(userInfo, 'zipCode')
    }
  });
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);

  const countryOptions = mapToSelect(countries, t);

  const onSubmit = data => {
    setSaving(true);
    dispatch(saveUserInfo(data))
      .then(() => {
        reset(data);
        setSaved(true);
        setTimeout(() => {
          setSaved(false);
        }, 4000);
      })
      .catch(() => setError(true))
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="col-span-3">
        <TextInput
          name="firstName"
          label={t('firstName')}
          autoComplete="given-name"
          register={register}
          id="firstName"
        />
      </div>

      <div className="col-span-3">
        <TextInput name="lastName" label={t('lastName')} autoComplete="family-name" register={register} id="lastName" />
      </div>

      <div className="col-span-3">
        <TextInput name="email" label={t('email')} autoComplete="email" type="email" register={register} id="email" />
      </div>

      <div className="col-span-3">
        <TextInput name="phone" label={t('phoneNumber')} autoComplete="tel" register={register} id="phone" />
      </div>

      <div className="col-span-3">
        <Select
          control={control}
          name="country"
          inputId="country"
          label={t('country')}
          isSearchable
          options={countryOptions}
          autoComplete="country-name"
        ></Select>
      </div>

      <div className="col-span-3">
        <TextInput name="city" label={t('city')} autoComplete="address-level2" register={register} id="city" />
      </div>

      <div className="col-span-3">
        <TextInput
          name="street"
          label={t('streetAddress')}
          autoComplete="street-address"
          register={register}
          id="street"
        />
      </div>

      <div className="col-span-3">
        <TextInput name="zipCode" label={t('zip')} autoComplete="postal-code" register={register} id="zipCode" />
      </div>

      <div className="col-span-3 md:col-span-full flex justify-end mt-2">
        <Button type="submit" loading={saving} disabled={saving || !formState.isDirty}>
          {saved ? t('saved') : t('save')}
        </Button>
      </div>
    </Form>
  );
};

export default General;
