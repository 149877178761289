const strongPasswordRegex = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');

export const passwordValidator = password => {
  const passes = strongPasswordRegex.test(password);
  return passes
    ? null
    : `*Minimum 8 characters\n*1 lowercase letter (a-z)\n*1 uppercase letter (A-Z)\n*1 digit (0-9)\n*1 special character (#!=?)`;
};

export const matchesPassword = firstPassword => password => {
  const matches = password === firstPassword;
  return matches ? null : `Passwords do not match!`;
};
