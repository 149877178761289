import { useState } from 'react';
import classNames from 'classnames';
import FadeIn from '../../components/FadeIn';
import SectionHeading from '../../components/SectionHeading';
import Sell from './sell';
import Buy from './buy';

const Market = () => {
  const [tab, setTab] = useState('buy');

  const TABS = {
    buy: <Buy />,
    sell: <Sell />
  };

  const activeClasses = 'text-[#761185] border-[#761185]';
  return (
    <FadeIn>
      <SectionHeading>Market</SectionHeading>

      <div className="pb-28">
        <div className="hidden md:flex gap-8 border-b">
          <button
            onClick={() => setTab('buy')}
            className={classNames('pb-4 border-b-2', tab === 'buy' ? activeClasses : 'border-transparent')}
          >
            Buy
          </button>
          <button
            onClick={() => setTab('sell')}
            className={classNames('pb-4 border-b-2', tab === 'sell' ? activeClasses : 'border-transparent')}
          >
            Sell
          </button>
        </div>

        <div>
          <select
            id={'market-select'}
            onChange={e => setTab(e.target.value)}
            className="block md:hidden w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#761185] sm:leading-6"
          >
            <option value="buy">Buy</option>
            <option value="sell">Sell</option>
          </select>
        </div>

        <div className="mt-8 xxl:mt-12">{TABS[tab]}</div>
      </div>
    </FadeIn>
  );
};

export default Market;
