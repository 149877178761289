import { GET_USER_INFO_SUCCESS } from './user.js';
import { REQUEST_ACTIONS, restrictedRequest } from '../services/request';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_FAIL = 'SET_PASSWORD_FAILURE';
export const GET_ACTIVE_SESSION_SUCCESS = 'GET_ACTIVE_SESSION_SUCCESS';

const API_URL = `${import.meta.env.VITE_API_URL}/auth`;

export const signup = ({ email, language }) =>
  restrictedRequest({ action: REQUEST_ACTIONS.POST, url: `${API_URL}/signup`, data: { email, language } });

export const login =
  ({ email, password, t }) =>
  async dispatch => {
    try {
      const response = await restrictedRequest({
        action: REQUEST_ACTIONS.POST,
        url: `${API_URL}/login`,
        data: { email, password }
      });
      const user = response.data.user;
      dispatch({ type: LOGIN_SUCCESS });
      dispatch({ type: GET_USER_INFO_SUCCESS, payload: user });
    } catch (err) {
      dispatch({ type: LOGIN_FAIL });
      throw err;
    }
  };

const _getAutoStartUrl = autoStartToken => {
  const userAgent = window.navigator.userAgent;
  const pc = `bankid:///?autostarttoken=${autoStartToken}&redirect=`;
  const mobile = `https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=`;
  if (['Mobile', 'iPhone', 'Android'].some(x => userAgent.includes(x))) {
    return mobile;
  }
  return pc;
};

export const initiateBankIdLoginOnThisDevice = async () => {
  const response = await restrictedRequest({ action: REQUEST_ACTIONS.GET, url: `${API_URL}/bankid/initiate` });
  const { orderRef, autoStartToken } = response.data;
  const autoStartUrl = _getAutoStartUrl(autoStartToken);
  window.location.href = autoStartUrl;
  return { orderRef, autoStartUrl };
};

export const loginBankIdUser =
  ({ userId, loginToken }) =>
  async dispatch => {
    try {
      const response = await restrictedRequest({
        action: REQUEST_ACTIONS.POST,
        url: `${API_URL}/loginbankid`,
        data: { userId, loginToken }
      });
      const user = response.data.user;
      dispatch({ type: LOGIN_SUCCESS });
      dispatch({ type: GET_USER_INFO_SUCCESS, payload: user });
    } catch (err) {
      dispatch({ type: LOGIN_FAIL });
      throw err;
    }
  };

export const logout = () => async dispatch => {
  await restrictedRequest({ action: REQUEST_ACTIONS.POST, url: `${API_URL}/logout`, data: null, dispatch });
  dispatch({ type: LOGOUT_SUCCESS });
};

export const getActiveSession = () => async dispatch => {
  try {
    const response = await restrictedRequest({
      action: REQUEST_ACTIONS.GET,
      url: `${API_URL}/activesession`,
      dispatch
    });
    const user = response.data.user;
    dispatch({ type: GET_ACTIVE_SESSION_SUCCESS });
    dispatch({ type: GET_USER_INFO_SUCCESS, payload: user });
  } catch (ignore) {}
};

export const sendResetPasswordEmail = ({ email, language }) =>
  restrictedRequest({ action: REQUEST_ACTIONS.POST, url: `${API_URL}/resetpassword`, data: { email, language } });

export const setPassword =
  ({ userId, password }) =>
  async dispatch => {
    try {
      const response = await restrictedRequest({
        action: REQUEST_ACTIONS.POST,
        url: `${API_URL}/setpassword`,
        data: { userId, password }
      });
      const user = response.data.user;
      dispatch({ type: SET_PASSWORD_SUCCESS });
      dispatch({ type: GET_USER_INFO_SUCCESS, payload: user });
      return user;
    } catch (err) {
      dispatch({ type: SET_PASSWORD_FAIL });
      throw err;
    }
  };

export const checkSetPasswordLink = ({ token, userId }) =>
  restrictedRequest({ action: REQUEST_ACTIONS.POST, url: `${API_URL}/checksetpasswordlink`, data: { token, userId } });

export const checkForgotPasswordLink = ({ token, userId }) =>
  restrictedRequest({
    action: REQUEST_ACTIONS.POST,
    url: `${API_URL}/checkforgotpasswordlink`,
    data: { token, userId }
  });
