import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTotalInvestedAmount, getInvestmentHistory } from '../actions/investment';

const useInvestmentDetails = () => {
  const dispatch = useDispatch();
  const investedAmount = useSelector(state => state.investments.totalInvestedAmount);
  const investments = useSelector(state => state.investments.investmentHistory);

  useEffect(() => {
    if (investedAmount === null) {
      dispatch(getTotalInvestedAmount());
    }
  }, [dispatch, investedAmount]);

  useEffect(() => {
    if (!investments) {
      dispatch(getInvestmentHistory());
    }
  }, [dispatch, investments]);

  const interestRate = 0.05;
  const timePeriodInYears = 1;
  const accumulatedInterest = (investedAmount || 0) * interestRate * timePeriodInYears;
  const totalCapital = (investedAmount || 0) + accumulatedInterest;
  const numberOfInvestments = investments?.length || 0;

  const investedAmountFormatted = useMemo(() => {
    if (investedAmount !== null) {
      return investedAmount.toLocaleString('sv-SE', { useGrouping: true });
    }
  }, [investedAmount]);

  const accumulatedInterestFormatted = useMemo(() => {
    if (accumulatedInterest !== null) {
      return accumulatedInterest.toLocaleString('sv-SE', { useGrouping: true });
    }
  }, [accumulatedInterest]);

  const totalCapitalFormatted = useMemo(() => {
    if (totalCapital !== null) {
      return totalCapital.toLocaleString('sv-SE', { useGrouping: true });
    }
  }, [totalCapital]);

  return {
    investedAmount,
    accumulatedInterest,
    totalCapital,
    investments,
    numberOfInvestments,
    investedAmountFormatted,
    accumulatedInterestFormatted,
    totalCapitalFormatted
  };
};

export default useInvestmentDetails;
