import { Link } from 'react-router-dom';
import Stepper from '../Stepper/Stepper';
import UserDropdown from './UserDropdown';
import { changeLanguage, useLanguage } from '../../i18n/language';
import useIsMobile from '../../hooks/useIsMobile';

const TopNavigation = () => {
  const isMobile = useIsMobile();
  const language = useLanguage();

  return (
    <div className="top-navigation bg-white border-b border-slate-200">
      <Link to="/" className="flex ml-2 md:-ml-3">
        <img src={isMobile ? '/ji_logo.png' : '/logotyp-big-dark.png'} alt="Jibba" style={{ height: 48 }} />
        {import.meta.env.VITE_ENV === 'dev' && <span className="text-xl text-red-500">DEV</span>}
        {import.meta.env.VITE_ENV === 'test' && <span className="text-xl text-yellow-500">TEST</span>}
      </Link>
      <Stepper />
      <div className="flex items-center gap-6">
        <button onClick={() => changeLanguage(language === 'en' ? 'sv' : 'en')} className="uppercase">
          {language === 'en' ? 'sv' : 'en'}
        </button>
        <UserDropdown />
      </div>
    </div>
  );
};

export default TopNavigation;
