import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from 'recharts';
import Panel from '../Panel';
import PanelHeading from '../PanelHeading';
import { Select } from '../Inputs';
import Stat from '../Stat';
import InvestmentProgress from '../InvestmentProgress';
import useIsMobile from '../../hooks/useIsMobile';
import useInvestmentDetails from '../../hooks/useInvestmentDetails';
import Button from '../Button';

const COLORS = ['#4f46e5', '#8b5cf6', '#39bdf8', '#14b8a6', '#e879f9'];
const isProd = import.meta.env.VITE_ENV === 'production';

const Stats = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { investedAmountFormatted, accumulatedInterestFormatted, numberOfInvestments } = useInvestmentDetails();
  const [showProgress, setShowProgress] = useState(false);

  const loans = [
    /* { name: `${t('loan')} 1`, value: 35 },
    { name: `${t('loan')} 2`, value: 25 },
    { name: `${t('loan')} 3`, value: 40 } */
  ];

  const types = [
    /* { name: t('apartments'), value: 0 },
    { name: t('houses'), value: 0 } */
  ];

  const locations = [
    /* { name: 'Malmö', value: 70 },
    { name: 'Göteborg', value: 20 },
    { name: 'Stockholm', value: 10 } */
  ];

  const statOptions = [
    { label: t('loans'), value: 'loans' },
    { label: t('objectType'), value: 'types' },
    { label: t('geographicalDistribution'), value: 'locations' }
  ];

  const [selectedData, setSelectedData] = useState(types);

  const isDataEmpty = selectedData.every(data => data.value === 0);

  const handleSelectChange = option => {
    const selectedValue = option.value;

    if (selectedValue === 'loans') {
      setSelectedData(loans);
    } else if (selectedValue === 'types') {
      setSelectedData(types);
    } else if (selectedValue === 'locations') {
      setSelectedData(locations);
    }
  };

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" fontSize={12} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const renderEmptyLabel = ({ cx, cy }) => (
    <text x={cx} y={cy} fill="black" fontSize={12} textAnchor={'middle'} dominantBaseline="central">
      Ingen data
    </text>
  );

  return (
    <Panel>
      <header className="flex justify-between items-center pb-8 border-b">
        <PanelHeading title={t('statistics')} />
        <Select
          className="w-32 md:w-60"
          onChange={handleSelectChange}
          options={statOptions}
          defaultValue={{ label: t('objectType'), value: 'types' }}
        />
      </header>
      <div className="flex flex-col justify-between pt-8 md:flex-row">
        <div className="flex flex-col justify-between w-full">
          <div className="md:pr-8 divide-y">
            <Stat value={numberOfInvestments}>{t('investments')}</Stat>
            <Stat value={`${investedAmountFormatted} kr`}>{t('totalAmountInvested')}</Stat>
            <Stat value={`+${accumulatedInterestFormatted} kr`}>{t('interestIncome')}</Stat>
            {showProgress && <InvestmentProgress />}
            {!isProd && (
              <div className="flex justify-center pt-6">
                <Button small secondary onClick={() => setShowProgress(!showProgress)}>
                  {`${showProgress ? t('hide') : t('show')} ${t('investmentProgress')}`}
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-center w-full md:border-l">
          <ResponsiveContainer width={isMobile ? '100%' : '80%'} height={isMobile ? 360 : 280}>
            <PieChart>
              {!isDataEmpty && (
                <Pie
                  data={selectedData}
                  labelLine={false}
                  outerRadius={120}
                  innerRadius={45}
                  fill="#761185"
                  dataKey="value"
                  label={renderCustomizedLabel}
                >
                  {selectedData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              )}
              {isDataEmpty && (
                <Pie
                  data={[{ name: t('noData'), value: 1 }]}
                  labelLine={false}
                  outerRadius={isMobile ? 90 : 120}
                  innerRadius={isMobile ? 45 : 55}
                  fill="#761185"
                  dataKey="value"
                  label={renderEmptyLabel}
                  stroke="none"
                />
              )}
              {!isDataEmpty && (
                <Legend
                  align={isMobile ? 'top' : 'right'}
                  verticalAlign={isMobile ? 'top' : 'middle'}
                  layout="vertical"
                  formatter={value => <span className="ml-2 text-gray-600">{value}</span>}
                />
              )}
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </Panel>
  );
};

export default Stats;
