import {
  COMPLETED_KYC,
  GET_KYC_SUCCESS,
  GET_USER_INFO_SUCCESS,
  SAVE_KYC_SUCCESS,
  SAVE_USER_INFO_SUCCESS
} from '../actions/user';
import {
  BANK_ACCOUNT_REMOVAL_SUCCESS,
  BANK_ACCOUNT_VERIFICATION_SUCCESS,
  GET_BANK_ACCOUNTS_SUCCESS
} from '../actions/bankAccount';
import { LOGOUT_SUCCESS } from '../actions/auth';
import { CLICKED_NOTIFY_BEFORE_LAUNCH } from '../actions/investment';

const initialState = { userInfo: null, kyc: null, bankAccounts: null };

export default function userReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_USER_INFO_SUCCESS:
    case SAVE_USER_INFO_SUCCESS:
    case CLICKED_NOTIFY_BEFORE_LAUNCH:
      return { ...state, userInfo: { ...state.userInfo, ...payload } };
    case GET_KYC_SUCCESS:
    case SAVE_KYC_SUCCESS:
      return { ...state, kyc: payload };
    case COMPLETED_KYC:
      return { ...state, userInfo: { ...state.userInfo, hasCompletedKYC: true } };
    case GET_BANK_ACCOUNTS_SUCCESS:
      return { ...state, bankAccounts: payload };
    case BANK_ACCOUNT_VERIFICATION_SUCCESS:
      return { ...state, bankAccounts: [...(state.bankAccounts || []), payload] };
    case BANK_ACCOUNT_REMOVAL_SUCCESS:
      return { ...state, bankAccounts: state.bankAccounts.filter(bankAccount => bankAccount._id !== payload) };
    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
}
