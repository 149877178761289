import { combineReducers } from 'redux';
import auth from './auth';
import user from './user';
import investments from './investments';

export default combineReducers({
  auth,
  user,
  investments
});
