import { useState, useEffect } from 'react';

const FadeIn = ({ children }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setVisible(true);
    }, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div className="opacity-0 transition-opacity" style={{ opacity: visible ? 1 : 0 }}>
      {children}
    </div>
  );
};

export default FadeIn;
