import { forwardRef } from 'react';
import { useSelector } from 'react-redux';

const ProfileInfo = forwardRef((_, ref) => {
  const { firstName, lastName, email } = useSelector(state => state.user.userInfo);
  const name = firstName && lastName ? [firstName, lastName].join(' ') : null;

  return (
    <div className="flex flex-col px-4 py-2 text-left" ref={ref}>
      {name && <p className="text-sm font-semibold text-gray-900">{name}</p>}
      {email && <p className="text-sm text-gray-500 truncate">{email}</p>}
    </div>
  );
});

ProfileInfo.displayName = 'ProfileInfo';

export default ProfileInfo;
