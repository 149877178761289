import { useTranslation } from 'react-i18next';
import EarningCard from './EarningCard';

const EarningCards = ({ totalCapitalFormatted, investedAmountFormatted, accumulatedInterestFormatted }) => {
  const { t } = useTranslation();

  return (
    <div className="flex mt-10 gap-10 flex-col md:flex-row">
      <EarningCard
        title={t('totalCapital')}
        value={`${totalCapitalFormatted} SEK`}
        percentage="0.00%"
        period={t('sinceLastMonth')}
      />
      <EarningCard
        title={t('investedCapital')}
        value={`${investedAmountFormatted} SEK`}
        percentage="0.00%"
        period={t('total')}
      />
      <EarningCard
        title={t('availableCapital')}
        value={`${accumulatedInterestFormatted} SEK`}
        percentage="0.00%"
        period={t('total')}
      />
    </div>
  );
};

export default EarningCards;
