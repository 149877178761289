import classNames from 'classnames';

const TextInput = ({ name, label, autoComplete, type, register, large, className, ...rest }) => (
  <>
    {label && (
      <label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
    )}
    <div className="mt-1">
      <input
        type={type ?? 'text'}
        autoComplete={autoComplete}
        className={classNames(
          'block w-full rounded-md border-0 py-1.5 no-spinner text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#761185] sm:text-sm sm:leading-6',
          large ? 'py-3 sm:text-base' : 'sm:text-sm',
          className || ''
        )}
        {...register(name)}
        {...rest}
      />
    </div>
  </>
);

export default TextInput;
