import MembershipTable from '../../components/MembershipTable';
import BankAccounts from '../../components/BankAccounts';

const isProd = import.meta.env.VITE_ENV === 'production';

export default function Account() {
  return (
    <div className="column gap-6">
      {!isProd && <BankAccounts />}
      <MembershipTable />
    </div>
  );
}
